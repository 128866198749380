import { BaseSyntheticEvent, Dispatch, SetStateAction } from 'react';
import { CropSettingsProps } from '../UploadSignatureDialog';

interface SettingsCropProps {
  imgSrc: string;
  setCropSettings: Dispatch<SetStateAction<CropSettingsProps>>;
  display: string;
}

export const SettingsCrop = ({
  imgSrc,
  display,
  setCropSettings,
}: SettingsCropProps) => {
  return (
    <div className={`${display} py-4 row d-flex align-items-center`}>
      <div className="col-4">
        <button
          className="btn btn-outline-primary me-3"
          disabled={!imgSrc}
          onClick={() =>
            setCropSettings((state: CropSettingsProps) => ({
              ...state,
              rotate: state.rotate - 90,
            }))
          }
        >
          <i className="icon-decrease-rotate" />
        </button>
        <button
          className="btn btn-outline-primary"
          disabled={!imgSrc}
          onClick={() =>
            setCropSettings((state: CropSettingsProps) => ({
              ...state,
              rotate: state.rotate + 90,
            }))
          }
        >
          <i className="icon-increase-rotate" />
        </button>
      </div>
      <div className="col-8 d-flex align-items-center bg-white p-2">
        <i className="icon-zoom-in text-description"></i>
        <input
          className="form-range mx-2"
          type="range"
          min="10"
          max="200"
          defaultValue="100"
          disabled={!imgSrc}
          onChange={(e: BaseSyntheticEvent) => {
            setCropSettings((state: CropSettingsProps) => ({
              ...state,
              scale: e.target?.value / 100,
            }));
          }}
        />
        <i className="icon-zoom-out text-description"></i>
      </div>
    </div>
  );
};
