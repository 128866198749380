import { useMemo } from 'react';

interface UploadSignatureLabelProps {
  file: {
    name: string;
    size: number;
  };
  handleRemove: () => void;
  display: string;
}

export const UploadSignatureLabel = ({
  file,
  handleRemove,
  display,
}: UploadSignatureLabelProps) => {
  const fileSize = useMemo(() => Math.floor(file.size / 100), [file]);

  return (
    <div className={`${display} row bg-white p-0 m-0 py-2 file-information`}>
      <div className="col-10 d-flex flex-column">
        <p className="m-0 file-name">{file?.name}</p>
        <p className="m-0 file-size">{fileSize} KB</p>
      </div>
      <div className="col-2 d-flex justify-content-end align-items-center">
        <i
          className="icon-delete text-error cursor-pointer"
          onClick={handleRemove}
        ></i>
      </div>
    </div>
  );
};
