import React from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.scss';
import { App } from './App';
import { THEMES } from './constants';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './store/AuthContext';
import store from './store';
import 'swiper/css/bundle';
import 'bootstrap/dist/js/bootstrap.bundle';
import { Theme } from './enums';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const theme = Object.keys(THEMES).find(
  (key: string) => key === window.REACT_APP_STYLES,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthContextProvider>
        <BrowserRouter>
          <ThemeSwitcherProvider
            defaultTheme={theme || Theme.LIGHTDOTGOV}
            themeMap={THEMES}
          >
            <App />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </AuthContextProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
