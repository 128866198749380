import { memo, FC } from 'react';

import { InputElementBase } from '../../models/';
import { InputType } from '../../enums';

export const CheckBoxTable: FC<InputElementBase> = memo(
  ({
    disabled,
    type,
    wrapperClassname,
    className,
    name,
    value,
    onChange,
    readOnly,
    hidden,
  }) => {
    return (
      <div className={wrapperClassname}>
        <div className={`${hidden ? 'd-none' : ''} form-check`}>
          <input
            className={`form-check-input checkbox-table ${className}`}
            type={type ?? InputType.CheckBox}
            name={name}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            checked={!!value}
          />
        </div>
      </div>
    );
  },
);
