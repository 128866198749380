import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';

import { Modal, Separator } from '../';
import {
  setTableSelection,
  tableSelectionSelector,
} from '../../store/tableSelection';

export interface Selection {
  name: string;
  onDelete?: ((items?: unknown[]) => Promise<void>) | null;
  icon?: string;
  text?: string;
}
interface FilterTableProps {
  selection?: Selection;
}

const ConfirmModal: FC<{
  setConfirmationDialog: () => void;
  deleteHandler: () => void;
  count: number;
  selection?: Selection;
}> = memo(({ setConfirmationDialog, deleteHandler, count, selection }) => {
  return createPortal(
    <Modal
      title={`Are you sure you want to ${selection?.text?.toLowerCase()} the selected items (${count})?`}
      titleClass={selection?.text === 'Delete' ? 'text-error' : ''}
      subTitle={
        selection?.text === 'Delete'
          ? 'You will not be able to recover them'
          : undefined
      }
    >
      <div className="d-flex py-3 justify-content-between">
        <div className="d-inline-flex w-100">
          <button
            type="button"
            className="d-inline-flex justify-content-center btn btn-outline-primary cancel-button"
            onClick={setConfirmationDialog}
          >
            Cancel
          </button>
        </div>
        <div className="d-inline-flex w-100">
          <button
            type="button"
            className={`d-inline-flex justify-content-center align-items-center btn text-white ${
              selection?.text === 'Delete'
                ? 'btn-danger delete-button'
                : 'btn-primary archive-button'
            }`}
            onClick={deleteHandler}
          >
            <i className={`${selection?.icon} mx-2`} />
            {selection?.text}
          </button>
        </div>
      </div>
    </Modal>,
    document.querySelector('#root') as Element,
  );
});

export const FilterTableSelection: FC<FilterTableProps> = memo(
  ({ selection }) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState<boolean>(false);
    const toggle = useCallback(() => setOpen((state) => !state), []);

    const tableSelection = useSelector(
      tableSelectionSelector(selection?.name ?? ''),
    );

    const hiddeActions = useMemo(
      () => selection && !Object.hasOwn(selection, 'onDelete'),
      [selection],
    );

    const onConfirm = useCallback(() => {
      selection?.onDelete?.(tableSelection).finally(() => {
        toggle();
        dispatch(
          setTableSelection({ key: selection?.name, records: undefined }),
        );
      });
    }, [selection?.onDelete, tableSelection]);

    const onDeselectTableRows = useCallback(() => {
      selection?.name &&
        dispatch(
          setTableSelection({ key: selection?.name, records: undefined }),
        );
    }, []);

    const toggleConfirmationModalHandler = useCallback(
      (show: boolean) => () => setOpen(show),
      [],
    );

    return (
      <>
        {!!tableSelection?.length && (
          <div className="d-flex align-items-center px-2 mx-2 py-0 my-1 selection-options">
            <div className="d-inline-flex text-selection">
              {tableSelection?.length} selected
            </div>
            <div className="d-inline-flex ms-1">
              <button
                type="button"
                className="d-inline-flex btn btn-button-link border-0 px-1"
                onClick={onDeselectTableRows}
              >
                <i
                  style={{ fontSize: '11px' }}
                  className="icon-close-selection text-danger"
                ></i>
              </button>
            </div>
            <div className="d-inline-flex">
              <Separator className="mx-2 selection-separator" />
            </div>
            <div className={`d-inline-flex ${hiddeActions ? 'd-none' : ''}`}>
              <button
                type="button"
                className="d-inline-flex align-items-center btn btn-button-link border-0 px-1"
                onClick={toggle}
                hidden={selection?.onDelete === undefined}
                disabled={selection?.onDelete === null}
              >
                <i
                  style={{ fontSize: '12px' }}
                  className={`${selection?.icon} text-primary`}
                ></i>
              </button>
            </div>
          </div>
        )}
        {open && (
          <ConfirmModal
            setConfirmationDialog={toggleConfirmationModalHandler(false)}
            deleteHandler={onConfirm}
            count={tableSelection?.length}
            selection={selection}
          />
        )}
      </>
    );
  },
);
