import { FC, memo } from 'react';

interface DelimiterProps {
  className?: string;
  height?: string;
}

export const Delimiter: FC<DelimiterProps> = memo(
  ({ className = 'my-2', height = '1px' }) => (
    <div style={{ height }} className={`${className} w-100`}></div>
  ),
);
