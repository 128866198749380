import styles from './Login.module.scss';
import { ZAM_FOOTER_TEXT, DOTGOV_FOOTER_TEXT } from '../../constants';
import { LoginForm } from '../../components';
import { Theme } from '../../enums';
import { FC, memo } from 'react';

export const Login: FC = memo(() => {
  const handleSubmit = () => {
    window.location.replace(
      `${window.REACT_APP_API_URL}/login?redirectUri=${window.location.origin}`,
    );
  };

  return (
    <div className={`${styles.loginWrapper} container-fluid min-vh-100`}>
      <div className="row justify-content-center align-content-center min-vh-95 flex-column">
        <LoginForm
          handleSubmit={handleSubmit}
          buttonLabel={'Sign in with ZamPass'}
          verified={true}
        />
      </div>
      <footer className="d-flex justify-content-center text-white p5-400">
        {window.REACT_APP_STYLES === Theme.LIGHTZAM
          ? ZAM_FOOTER_TEXT
          : DOTGOV_FOOTER_TEXT}
      </footer>
    </div>
  );
});
