import { FC, memo } from 'react';

import { StatusInfo } from '../../interfaces';

import styles from './Status.module.scss';

interface StatusProps {
  config: StatusInfo;
}

export const Status: FC<StatusProps> = memo(({ config }) => (
  <div className="status">
    <i
      className={`${styles.statusIcon} ${config.icon} ${config.className} me-1 custom-fs-2 d-inline-block align-middle`}
    />
    <p
      className={`${config.className} d-inline-block m-0 align-middle open-sans-semibold`}
    >
      {config.text}
    </p>
  </div>
));
