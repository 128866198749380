import {
  FC,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Column, Props, SortColumn } from '../../interfaces';
import { ICON_NAME_MAP } from '../../constants';
import { getSearchParams, updateSearchParams } from '../../utils/utils';

interface TableHeadProps {
  item: Column;
  text: string | null;
  onSubmit?: (values: Props) => void;
}

const DefaultConfig: SortColumn = {
  key: 'sortingField',
  valueKey: 'asc',
};

export type SortRef = {
  onSort: (field: string) => void;
};

const SortIcon = memo(
  forwardRef<SortRef, Props>(
    ({ field, sort = DefaultConfig, setSorted, onSubmit }, ref) => {
      const [searchParams] = useSearchParams();
      const valueKey = (sort?.valueKey as string) ?? DefaultConfig.valueKey;
      const fieldKey = (sort?.key as string) ?? DefaultConfig.key;
      const sortValue = searchParams.get(valueKey);
      const sortingField = searchParams.get(fieldKey);
      const sorted = !!sortValue && sortingField === field;
      const navigate = useNavigate();

      const sortDirection = useMemo(
        () =>
          sortingField === field
            ? ICON_NAME_MAP.get(sortValue)
            : ICON_NAME_MAP.get(null),
        [field, sortingField, sortValue],
      );

      const onSort = useCallback(
        (fieldColumn: string) => {
          const sortParams =
            sortValue === 'false' && sortingField === fieldColumn
              ? {
                  [fieldKey]: false,
                  [valueKey]: false,
                }
              : {
                  [fieldKey]: fieldColumn,
                  [valueKey]: (
                    sortValue === null || sortingField !== fieldColumn
                  ).toString(),
                };
          const params = updateSearchParams(searchParams, sortParams);
          navigate('?' + params);
          const updatedParams = getSearchParams(searchParams);
          onSubmit({ ...updatedParams, ...sortParams });
        },
        [sort, sortingField, sortValue, searchParams],
      );

      useEffect(() => {
        setSorted(sorted);
      }, [sorted]);

      useImperativeHandle(ref, () => ({
        onSort,
      }));

      return <i className={sortDirection}></i>;
    },
  ),
);

export const TableHead: FC<TableHeadProps> = memo(
  ({ item, text, onSubmit }) => {
    const [sorted, setSorted] = useState(false);
    const sortIconRef = useRef<SortRef>(null);

    const onClicHandler = useCallback(() => {
      !!item?.sort && sortIconRef?.current?.onSort(item?.sort?.field as string);
    }, [item]);

    return (
      <th
        className={`${item?.sort ? 'cursor-pointer' : 'cursor-default'} ${
          sorted ? 'sorting-column' : ''
        }`}
        onClick={onClicHandler}
      >
        <div className="d-flex flex-row align-items-center position-relative">
          <div
            className={`${
              item?.sort ? 'd-flex' : 'd-inline-block'
            } m-0 w-100 justify-content-between align-middle open-sans-semibold`}
          >
            {text}
            {item?.sort && (
              <div className="d-flex align-items-center cursor-pointer">
                <SortIcon
                  sort={item?.sort}
                  field={item?.sort?.field as string}
                  setSorted={setSorted}
                  ref={sortIconRef}
                  onSubmit={onSubmit}
                />
              </div>
            )}
          </div>
        </div>
      </th>
    );
  },
);
