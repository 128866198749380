import { BaseSyntheticEvent, FC, memo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Select } from '../../components';
import {
  DEFAULT_PAGE_SIZE,
  ITEMS_PER_PAGE_OPTIONS,
  PAGE_KEY,
  PAGE_SIZE_KEY,
  PAGINATION_LIMIT,
} from '../../constants';
import {
  getDefaultPaginationParams,
  getPaginationItems,
  updateSearchParams,
} from '../../utils';
import { Params, Props } from '../../interfaces';
import { getSearchParams } from '../../utils/utils';

const ArrowLeftIcon = () => (
  <i style={{ fontSize: '11px' }} className="icon-arrow-left" />
);

const ArrowRightIcon = () => (
  <i style={{ fontSize: '11px' }} className="icon-arrow-right" />
);

const ThreeDots = () => <div className="align-items-center px-3">...</div>;

const PageButton: FC<{
  page: number;
  currentPage: number;
  handleChange: (index: number) => () => void;
}> = memo(({ page, currentPage, handleChange }) => (
  <button
    key={`${page}-page`}
    aria-pressed={currentPage === page}
    type="button"
    className="d-inline-flex align-items-center btn btn-primary btn-pagination"
    onClick={handleChange(page)}
  >
    {page}
  </button>
));

interface PaginationProps {
  totalItems?: number;
  onSubmit: (values: Props) => void;
}

export const Pagination: FC<PaginationProps> = memo(
  ({ totalItems = 0, onSubmit }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const pageSize = +(searchParams.get(PAGE_SIZE_KEY) ?? DEFAULT_PAGE_SIZE);
    const page = Number(searchParams.get(PAGE_KEY)) || 1;
    const count = Number(searchParams.get(PAGE_SIZE_KEY) || DEFAULT_PAGE_SIZE);
    const totalPages = Math.ceil(totalItems / pageSize) || 1;
    const limits = ['0', (totalPages + 1).toString()];
    const paginationItems = getPaginationItems(totalPages, page);
    const startItem = (page - 1) * count + 1;
    const pageLimit = page * count;
    const lastItem = pageLimit < totalItems ? pageLimit : totalItems;

    const navigateWithParams = useCallback(
      (config: Params) => {
        const params = updateSearchParams(
          searchParams,
          getDefaultPaginationParams(config),
        );
        navigate(`?${params}`);
        const updatedParams = getSearchParams(searchParams);
        onSubmit({ ...updatedParams, ...getDefaultPaginationParams(config) });
      },
      [searchParams],
    );

    const handleChange = useCallback(
      (nextPage: string | number) => () => {
        if (limits.includes(nextPage.toString())) return;
        navigateWithParams({
          [PAGE_KEY]: nextPage,
          [PAGE_SIZE_KEY]: searchParams.get(PAGE_SIZE_KEY),
        });
      },
      [navigateWithParams, searchParams],
    );

    const onChangeItemsPerPage = useCallback(
      (e: BaseSyntheticEvent | object) =>
        navigateWithParams({
          [PAGE_SIZE_KEY]: (e as BaseSyntheticEvent).target.value,
        }),
      [navigateWithParams],
    );

    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <button
            key="left-page"
            type="button"
            className="d-inline-flex align-items-center btn btn-primary btn-pagination"
            onClick={handleChange(page - 1)}
          >
            <ArrowLeftIcon />
          </button>
          <PageButton page={1} handleChange={handleChange} currentPage={page} />
          {page >= 4 && totalPages > PAGINATION_LIMIT && <ThreeDots />}
          {totalPages > 2 &&
            paginationItems.map((item, index) => (
              <PageButton
                key={`page-button-${index}`}
                page={item}
                handleChange={handleChange}
                currentPage={page}
              />
            ))}
          {page < totalPages - 2 && totalPages > PAGINATION_LIMIT && (
            <ThreeDots />
          )}
          {totalPages > 1 && (
            <PageButton
              page={totalPages}
              handleChange={handleChange}
              currentPage={page}
            />
          )}
          <button
            key="right-page"
            type="button"
            className="d-inline-flex align-items-center btn btn-primary btn-pagination"
            onClick={handleChange(page + 1)}
          >
            <ArrowRightIcon />
          </button>
        </div>
        <div className="d-flex position-static">
          <div>
            <Select
              onChange={onChangeItemsPerPage}
              options={ITEMS_PER_PAGE_OPTIONS}
              value={count.toString()}
              className="select-pagination"
            />
          </div>
          <div className="d-flex align-items-center ms-3">
            <div className="pagination-info">
              Viewing {startItem} to {lastItem} of {totalItems}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
