import React from 'react';
import { ACCEPTABLE_SIGNATURE_IMAGE_FORMAT } from '../../../../constants';

import styles from './UploadSignatureInput.module.scss';

interface UploadSignatureInputProps {
  onSelectFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  display: string;
}

export const UploadSignatureInput = ({
  onSelectFile,
  display,
}: UploadSignatureInputProps) => {
  return (
    <>
      <div className={`${display} form-group border-dashed-1`}>
        <div className="bg-grey-2 p-3" id="fileInputArea">
          <label className="d-flex w-100 cursor-pointer" htmlFor="formFile">
            <span className="text-primary semi-bold">
              <i className="icon-add-fill me-2 custom-fs-3" />
              Attach the File
            </span>
            <input
              className={`opacity-0 w-100 h-100 position-absolute ${styles.signatureUpload}`}
              type="file"
              id="formFile"
              onChange={onSelectFile}
              accept={ACCEPTABLE_SIGNATURE_IMAGE_FORMAT}
            />
            <div className="vr mx-5"></div>
            <span className="text-description">Or drag and drop here</span>
          </label>
        </div>
      </div>
      <div className={`${display} py-5`}>
        <div className="d-flex justify-content-center">
          <p className="text-input p1-400">File Format: .JPG .PNG</p>
        </div>
        <div className="d-flex justify-content-center">
          <p className="text-input p1-400">Image Max Size: 2 Mb</p>
        </div>
      </div>
    </>
  );
};
