import React, { FC, memo, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Delimiter, Footer, Header } from '../../components';
import { routes } from '../../models';
import { AuthContext } from '../../store/AuthContext';
import { getFullName } from '../../utils/utils';
import { useUser } from '../../hooks';
import { DEFAULT_FILTERS } from '../../constants';

import ProfileAvatar from '../../assets/images/icons/profile.svg';
import styles from './Profile.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

interface Item {
  link: string;
  label: string;
  icon: React.ReactNode;
}

interface MenuItemProps {
  item: Item;
  pathName: string;
}

const MENU_SETTINGS = [
  {
    link: routes.profileInfo,
    label: 'Profile Information',
    icon: <i className="icon-profile icon-primary"></i>,
  },
  {
    link: `${routes.profileHistory}?page=${DEFAULT_FILTERS.page}&count=${DEFAULT_FILTERS.count}`,
    label: 'Signature History',
    icon: <i className="icon-history icon-primary"></i>,
  },
  {
    link: `${routes.profileArchive}?page=${DEFAULT_FILTERS.page}&count=${DEFAULT_FILTERS.count}`,
    label: 'Documents Archive',
    icon: <i className="icon-documents-archive icon-primary"></i>,
  },
];

const MenuItem: FC<MenuItemProps> = memo(({ item, pathName }) => (
  <li
    className={`${
      styles.link
    } d-flex align-items-center border-0 overflow-hidden ${
      item.link.includes(pathName) ? `${styles.isActive} active` : ''
    }`}
  >
    <Link
      to={item.link}
      className="text-decoration-none px-3 text-black d-flex align-items-center w-100 h-100"
    >
      <div className={`${styles.menuIcon} d-flex justify-content-center`}>
        {item.icon}
      </div>
      <p className="ms-1 m-0 p3-400">{item.label}</p>
    </Link>
  </li>
));

export const Profile: FC<LayoutProps> = memo(({ children }: LayoutProps) => {
  const location = useLocation();
  const { claims, imageIndex } = useContext(AuthContext);
  const { getAvatar } = useUser();

  return (
    <>
      <Header />
      <main className="pt-5" id="mainContainer">
        <div className="container-fluid">
          <div className="row h-100">
            <div className="col-auto col-md-3 col-xl-2 px-0 sidebar">
              <div className="d-flex flex-column pt-1 h-100">
                <div className="w-100 d-flex p-3">
                  <div className="overflow-hidden rounded-circle">
                    <img
                      src={
                        imageIndex !== 0 ? getAvatar(imageIndex) : ProfileAvatar
                      }
                      alt="Avatar"
                      width="80"
                      height="80"
                    />
                  </div>
                  <div className="fs-6 ps-2 w-50 d-flex align-items-center">
                    <p className="m-0 open-sans-semibold full-name">
                      {getFullName(claims)}
                    </p>
                  </div>
                </div>
                <div>
                  <Delimiter className="m-0" />
                  <ul className="nav nav-pills flex-column position-relative profile-menu">
                    {MENU_SETTINGS.map((item, index) => (
                      <MenuItem
                        key={`menu-item-${index}`}
                        item={item}
                        pathName={location.pathname}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col py-3 container-inner">{children}</div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
});
