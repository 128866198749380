import { memo, FC } from 'react';

import { InputElementBase } from '../../models/';

import styles from './Checkbox.module.scss';

export const Checkbox: FC<InputElementBase> = memo(
  ({
    disabled,
    type,
    wrapperClassname,
    className,
    label,
    labelClass,
    name,
    value,
    onChange,
    readOnly,
    labelPosition,
    hidden,
  }) => {
    return (
      <div className={wrapperClassname}>
        <div
          className={`${
            hidden ? 'd-none' : ''
          } checkbox-container form-check form-switch focus-label`}
        >
          <label
            htmlFor={name}
            className={`form-check-label ${labelClass} ${labelPosition}`}
          >
            {label}
          </label>
          <input
            id={name}
            className={`form-check-input ${className} ${styles.checkbox}`}
            type={type}
            name={name}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            checked={!!value}
          />
        </div>
      </div>
    );
  },
);
