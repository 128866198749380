import { useCallback, useEffect, useState } from 'react';

import { Actions } from '../enums/history-key';

export function useTableActions<T>() {
  const [mode, setMode] = useState<Actions>(Actions.View);
  const [activeItem, setActiveItem] = useState<T>();

  const onActionClick = useCallback((action: Actions, row?: object) => {
    row && setActiveItem(row as any);
    setMode(action);
  }, []);

  const onClose = useCallback(() => setMode(Actions.View), []);

  useEffect(() => {
    mode === Actions.View && setActiveItem(undefined);
  }, [mode]);

  return {
    mode,
    setMode,
    activeItem,
    setActiveItem,
    onActionClick,
    onClose,
  };
}
