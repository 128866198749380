import Axios from '../utils/axios-config';
import { APIResponse } from '../interfaces/user-info';

export const useUser = () => {
  const getUserInfo = (): Promise<{ data: APIResponse }> => {
    return Axios.get(`${window.REACT_APP_API_URL}/users/info`);
  };

  const checkAvatar = () => {
    return Axios.get(`${window.REACT_APP_API_URL}/users/avatar`);
  };

  const getAvatar = (index: number) => {
    return `${window.REACT_APP_API_URL}/users/avatar?index=${index}`;
  };

  return {
    getUserInfo,
    checkAvatar,
    getAvatar,
  };
};
