import React from 'react';
import { useRoutes } from 'react-router-dom';
import {
  Wildcard,
  History,
  UserInfo,
  Sign,
  Verify,
  DocumentsArchive,
} from '../components';

import {
  PrivacyPolicy,
  Profile,
  TermsAndConditions,
  Unverified,
} from '../pages';
import { ProtectedRoute } from '../guard/ProtectedRoute';
import { routes } from '../models';

const Layout = React.lazy(() =>
  import('../components/Layout/Layout').then((module) => ({
    default: module.Layout,
  })),
);
const Login = React.lazy(() =>
  import('../pages/Login/Login').then((module) => ({ default: module.Login })),
);
const Home = React.lazy(() =>
  import('../pages/Home/Home').then((module) => ({ default: module.Home })),
);

export const Navigation = () => {
  return useRoutes([
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Layout>
            <Home />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: '/*',
      element: (
        <Layout>
          <Wildcard />
        </Layout>
      ),
    },
    {
      path: routes.login,
      element: <Login />,
    },
    {
      path: routes.unverified,
      element: <Unverified />,
    },
    {
      path: '/document',
      children: [
        {
          path: 'sign',
          element: (
            <ProtectedRoute>
              <Layout>
                <Sign />
              </Layout>
            </ProtectedRoute>
          ),
        },
        {
          path: 'verify',
          element: (
            <ProtectedRoute>
              <Layout>
                <Verify />
              </Layout>
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/profile',
      children: [
        {
          path: 'info',
          element: (
            <ProtectedRoute>
              <Profile>
                <UserInfo />
              </Profile>
            </ProtectedRoute>
          ),
        },
        {
          path: 'history',
          element: (
            <ProtectedRoute>
              <Profile>
                <History />
              </Profile>
            </ProtectedRoute>
          ),
        },
        {
          path: 'archive',
          element: (
            <ProtectedRoute>
              <Profile>
                <DocumentsArchive />
              </Profile>
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: routes.termsAndConditions,
      element: (
        <Layout>
          <TermsAndConditions />
        </Layout>
      ),
    },
    {
      path: routes.privacyPolicy,
      element: (
        <Layout>
          <PrivacyPolicy />
        </Layout>
      ),
    },
  ]);
};
