import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './Slider.module.scss';

// Icons for slider
import dotgovStep1 from '../../assets/images/icons/dotgov-step1.png';
import dotgovStep2 from '../../assets/images/icons/dotgov-step2.png';
import dotgovStep3 from '../../assets/images/icons/dotgov-step3.png';
import dotgovStep4 from '../../assets/images/icons/dotgov-step4.png';
import dotgovStep5 from '../../assets/images/icons/dotgov-step5.png';

// Icons for slider
import zamStep1 from '../../assets/images/icons/zam-step1.png';
import zamStep2 from '../../assets/images/icons/zam-step2.png';
import zamStep3 from '../../assets/images/icons/zam-step3.png';
import zamStep4 from '../../assets/images/icons/zam-step4.png';
import zamStep5 from '../../assets/images/icons/zam-step5.png';
import { Theme } from '../../enums';
import { FC, memo } from 'react';

interface SwiperItem {
  title: string;
  text: string;
  imagePath: string;
}

const SWIPER_ITEMS: SwiperItem[] = [
  {
    title: 'Step 1',
    text: 'How to e-sign a PDF document. Sign in to your ZamPass account, and click the [E-Sign Document] button.',
    imagePath:
      window.REACT_APP_STYLES === Theme.LIGHTZAM ? zamStep1 : dotgovStep1 ?? '',
  },
  {
    title: 'Step 2',
    text: 'Upload your document. Press [Upload File] button or drag and drop the PDF file into the upload area.',
    imagePath:
      window.REACT_APP_STYLES === Theme.LIGHTZAM ? zamStep2 : dotgovStep2 ?? '',
  },
  {
    title: 'Step 3',
    text: 'Drag and drop the [Signature] on your document, and click [Sign] button.',
    imagePath:
      window.REACT_APP_STYLES === Theme.LIGHTZAM ? zamStep3 : dotgovStep3 ?? '',
  },
  {
    title: 'Step 4',
    text: 'Check your mobile phone. Confirm your signature in ZamMobile application.',
    imagePath:
      window.REACT_APP_STYLES === Theme.LIGHTZAM ? zamStep4 : dotgovStep4 ?? '',
  },
  {
    title: 'Step 5',
    text: 'Congratulations! You have successfully signed the document. You can now download it or preview it in ZamMobile application.',
    imagePath:
      window.REACT_APP_STYLES === Theme.LIGHTZAM ? zamStep5 : dotgovStep5 ?? '',
  },
];

export const Slider: FC = memo(() => (
  <div className={`${styles.slide} slider-container container`}>
    <Swiper
      loop
      navigation
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, Navigation, A11y]}
      onSwiper={(swiper) => console.warn(swiper)}
      onSlideChange={() => console.warn('slide change')}
      className="pb-4"
    >
      {SWIPER_ITEMS.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="row mb-5 px-md-4 h-100">
            <div className="col-md-5 col-12 d-flex align-items-center">
              <img
                className="img-fluid"
                src={item.imagePath}
                alt="Tutorial images"
              />
            </div>
            <div className="col-md-7 col-12 d-flex justify-content-center flex-column">
              <h3 className="pb-2 text-main font-weight-500">{item.title}</h3>
              <h3 className="text-main font-weight-500">{item.text}</h3>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
));
