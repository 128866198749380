import { useState, useContext, Suspense, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import { Navigation } from './navigation/Navigation';
import { useUser } from './hooks';
import { AuthContext } from './store/AuthContext';
import { DEFAULT_ERROR_MESSAGE } from './constants';
import { routes } from './models';

import './App.scss';

export const App = () => {
  const [session, setSession] = useState<boolean>(false);
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const { getUserInfo } = useUser();

  useEffect(() => {
    getUserInfo()
      .then(({ data }) => {
        if (data?.isAuthenticated) {
          if (Number(data.claims?.trustLevel) <= 1) {
            navigate(`${routes.unverified}`);
          }
          context.login(data);
        } else {
          context.logout();
        }
      })
      .catch((error: AxiosError) => {
        toast.error(error.message || DEFAULT_ERROR_MESSAGE);
        context.logout();
      })
      .finally(() => {
        setSession(true);
      });
  }, []);
  return (
    <Suspense fallback="Loading application layout, wait please">
      {session && <Navigation />}
      <ToastContainer position="bottom-right" />
    </Suspense>
  );
};
