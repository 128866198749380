import { FC, memo, useMemo } from 'react';

import { Column, Props, RenderValue } from '../../interfaces';
import { TableHead } from './TableHead';
import { getValue } from '../../utils/utils';
import { DEFAULT_PAGE_SIZE, TABLE_COUNTER_COLUMN } from '../../constants';
import { Pagination } from '../';

import { TableRowCheck } from './TableRowCheck';
import { useSelector } from 'react-redux';
import { tableSelectionSelector } from '../../store/tableSelection';
import noData from '../../assets/images/icons/no-data.png';

import styles from './Table.module.scss';

interface TableProps {
  data: any;
  columns: Column[];
  name?: string;
  selectedRow?: Props;
  selectKey?: string;
  counter?: boolean;
  pagination?: {
    totalItems?: number;
    page?: number | string | null;
    count?: number | string | null;
  };
  onSubmit: (values: Props) => void;
  allowRowSelect?: boolean;
  activeId?: string;
}

export const Table: FC<TableProps> = memo(
  ({
    columns,
    name,
    data,
    pagination,
    onSubmit,
    allowRowSelect,
    selectedRow,
    selectKey = 'id',
    counter,
    activeId,
  }) => {
    const hasData = data && data.length > 0;
    const key = selectKey as keyof typeof selectedRow;
    const selectedRows = useSelector(tableSelectionSelector(name ?? '')) ?? [];

    const tableColumns = useMemo(() => {
      const cols = [...columns];
      allowRowSelect &&
        data?.length &&
        cols.unshift({
          headerText: (
            <TableRowCheck name={name} selectKey={key} data={data as Props[]} />
          ),
          field: 'table-select-row',
          width: '56px',
          render: (_, record: Props) => (
            <TableRowCheck
              name={name}
              selectKey={key}
              record={record}
              data={data as Props[]}
            />
          ),
        } as Column<Props>);
      counter && cols.unshift(TABLE_COUNTER_COLUMN as Column<Props>);

      return cols;
    }, [columns, counter, allowRowSelect, data]);

    const counterStart = useMemo(
      () =>
        (+(pagination?.page || 1) - 1) *
        +(pagination?.count || DEFAULT_PAGE_SIZE),
      [pagination],
    );

    const isRowChecked = (row: Props) =>
      !!selectedRows?.find((selection: Props) => selection[key] === row[key]);

    return (
      <table className={`table ${styles.historyTable} data-table m-0`}>
        <thead>
          <tr>
            {tableColumns?.map((item, index) => (
              <TableHead
                key={`header-${index}`}
                text={item.headerText as string}
                item={item as Column}
                onSubmit={onSubmit}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {hasData ? (
            data?.map((row: any, i: number) => {
              return (
                <tr
                  className={`${
                    isRowChecked(row) || activeId === row[key] ? 'selected' : ''
                  }`}
                  key={`row-${i}`}
                >
                  {tableColumns?.map((column: Column, index: number) => (
                    <td key={`cell-${index}`} width={column.width}>
                      {column.render?.(
                        getValue(row, column.field) as RenderValue,
                        row,
                        counterStart + i,
                      ) ?? (
                        <div className="d-flex align-items-center table-text p-1">
                          <p className="m-0 d-flex">
                            {getValue(row, column.field) as string}
                          </p>
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr className="data-empty">
              <td colSpan={tableColumns.length}>
                <p className="d-flex justify-content-center mb-0">
                  <img src={noData} />
                </p>
                <p className="text-center p-600">No data...</p>
              </td>
            </tr>
          )}
        </tbody>
        {pagination && hasData ? (
          <tfoot>
            <tr>
              <th colSpan={100}>
                <Pagination
                  totalItems={pagination?.totalItems}
                  onSubmit={onSubmit}
                />
              </th>
            </tr>
          </tfoot>
        ) : null}
      </table>
    );
  },
);
