export enum TermsAndConditionsId {
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  INTERPRETATION_AND_DEFINITIONS = 'interpretationAndDefinitions',
  ACKNOWLEDGEMENT = 'acknowledgement',
  LINKS = 'links',
  LICENCE = 'license',
  HYPERLINKING = 'hyperlinking',
  TERMINATION = 'termination',
  LIMITATION = 'limitationOfLiability',
  GOVERNING_LAW = 'governingLaw',
  SEVERABILITY_AND_WAIVER = 'severabilityAndWaiver',
  TRANSLATION = 'translationInterpretation',
  CODE_OF_CONDUCT = 'codeOfConduct',
  CHANGES_TERM_AND_CONDITIONS = 'Changes to These Terms and Conditions',
  CONTACT_US = 'Contact Us',
}
