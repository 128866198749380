import { BaseSyntheticEvent, FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CheckBoxTable } from '../../components';
import { Props } from '../../interfaces';
import {
  setTableSelection,
  tableSelectionSelector,
} from '../../store/tableSelection';
import { getValue, stopPropagation } from '../../utils';

interface TableRowCheckProps {
  name?: string;
  selectKey: string;
  data: Props[];
  record?: Props;
  isDisabled?: boolean;
}
export const TableRowCheck: FC<TableRowCheckProps> = memo(
  ({ name, selectKey, data, record, isDisabled = false }) => {
    const dispatch = useDispatch();

    const selectedRows = useSelector(tableSelectionSelector(name ?? '')) ?? [];
    const selectedRowIds = useMemo(
      () => selectedRows?.map((item: Props) => item[selectKey]),
      [selectedRows],
    );

    const allRowsSelected = useMemo(
      () =>
        data?.every((item) =>
          selectedRowIds?.includes(getValue(item, selectKey)),
        ),
      [data, selectedRowIds],
    );
    const isIndeterminate = useMemo(
      () =>
        data?.some((item) =>
          selectedRowIds?.includes(getValue(item, selectKey)),
        ) && !allRowsSelected,
      [data, allRowsSelected, selectedRowIds],
    );

    const handleRowSelection = useCallback(
      (recordTable?: Props) => (e: BaseSyntheticEvent) => {
        const records = e.target.checked
          ? [...selectedRows, recordTable]
          : selectedRows?.filter(
              (item: Props) =>
                recordTable &&
                item[selectKey] !== (recordTable as Props)[selectKey],
            );

        dispatch(setTableSelection({ key: name ?? '', records }));
      },
      [selectedRows, name],
    );

    const handleAllRowSelection = useCallback(
      (e: BaseSyntheticEvent) => {
        const dataIds = data.map((item) => item[selectKey]);
        const records = isIndeterminate
          ? [
              ...selectedRows,
              ...data.filter(
                (item) => !selectedRowIds.includes(item[selectKey]),
              ),
            ]
          : !e.target.checked
          ? selectedRows.filter(
              (item: Props) => !dataIds.includes(item[selectKey]),
            )
          : [...selectedRows, ...data];

        dispatch(setTableSelection({ key: name ?? '', records }));
      },
      [isIndeterminate, data, selectedRows],
    );

    return (
      <div
        className="d-flex align-items-center justify-content-center table-text"
        onClick={stopPropagation}
      >
        {record ? (
          <CheckBoxTable
            name="row-check"
            value={selectedRowIds.includes(getValue(record, selectKey))}
            onChange={handleRowSelection(record)}
            disabled={isDisabled}
          />
        ) : (
          <CheckBoxTable
            name="row-check"
            value={allRowsSelected}
            onChange={handleAllRowSelection}
            disabled={isDisabled}
          />
        )}
      </div>
    );
  },
);
