import React from 'react';
import contentSvg from '../../assets/images/icons/content.svg';
import { MenuItems } from '../../interfaces';

export const MenuContent = ({ menuItems }: { menuItems: MenuItems[] }) => {
  return (
    <div className="card py-4">
      <div className="d-flex px-4 mb-4">
        <img src={contentSvg} alt="Content" />
        <p className="fw-bold ms-3 d-flex mb-0 align-items-center fs-6">
          Content
        </p>
      </div>
      <aside className="mx-2">
        <ol>
          {menuItems.map((item) => {
            return (
              <li key={item.id} className="mb-3">
                <a
                  href={`#${item.id}`}
                  className="link-warning text-decoration-none semi-bold"
                >
                  {item.label}
                </a>
              </li>
            );
          })}
        </ol>
      </aside>
    </div>
  );
};
