export enum PrivacyPolicyId {
  PRIVACY_POLICY = 'privacyPolicy',
  INTERCEPTION_DEFINITION = 'interpretationAndDefinitions',
  PERSONAL_DATA = 'personalData',
  TYPE_OF_DATA = 'typeOfData',
  TYPE_OF_DATA_COLLECTED = 'typeOfDataCollected',
  USE_PERSONAL_DATA = 'usePersonalData',
  RETENTION_PERSONAL_DATA = 'retentionPersonalData',
  TRANSFER_PERSONAL_DATA = 'transferPersonalData',
  DISCLOSURE_PERSONAL_DATA = 'disclosurePersonalData',
  SECURITY_PERSONAL_DATA = 'securityPersonalData',
  COOKIES = 'cookies',
  LINKS = 'links',
  CONTACT_US = 'contactUs',
  CHANGES_TO_PRIVACY_POLICY = 'changesToPrivacyPolicy',
}
