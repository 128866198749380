import { BaseSyntheticEvent } from 'react';

import DotGovUploadDocumentIcon from '../../assets/images/icons/dotgov-upload-document-icon.svg';

import styles from './DocumentUploadInput.module.scss';

export const DocumentUploadInput = ({
  onChange,
}: {
  onChange: (e: BaseSyntheticEvent) => void;
}) => {
  return (
    <div
      className={`border-dashed d-flex flex-column align-items-center justify-content-center h-100 min-vh-50 ${styles.fileUploadContainer}`}
    >
      <img
        src={DotGovUploadDocumentIcon}
        width={140}
        height={140}
        alt="Document PDF icon"
      />
      <label
        htmlFor="file"
        className={`btn btn-outline-primary d-flex justify-content-center align-items-center semi-bold my-5 ${styles.fileUploadButton}`}
      >
        Upload File
      </label>
      <input
        type="file"
        id={styles['fileUpload']}
        onChange={onChange}
        accept="application/pdf"
        placeholder="Upload File"
      />
      <h4 className="text-disabled semi-bold">or Drag and Drop File here</h4>
    </div>
  );
};
