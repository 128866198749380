import { FC, memo } from 'react';

interface SeparatorProps {
  className?: string;
  width?: string;
}

export const Separator: FC<SeparatorProps> = memo(
  ({ className = 'mx-2', width = '1px' }) => (
    <div style={{ width }} className={`separator ${className} h-100`}></div>
  ),
);
