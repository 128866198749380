import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Column,
  HistoryFilters,
  HistoryResponse,
  ProfileHistory,
  Props,
  RenderValue,
} from '../../interfaces';
import { DocumentStatus, Pages } from '../../enums';
import { HistoryDetails } from '../HistoryDetails/HistoryDetails';
import { useDocument, useTableActions } from '../../hooks';
import { AuthContext } from '../../store/AuthContext';
import {
  getSearchParams,
  handleError,
  updateSearchParams,
} from '../../utils/utils';
import {
  ActionButtons,
  ConfirmModal,
  FilterTable,
  Table,
} from '../../components';
import {
  COLUMNS,
  getFilterItemsByPage,
  getHistoryFilters,
} from '../../utils/constants';
import {
  CONFIG_MODAL_MAP,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_FILTERS,
} from '../../constants';
import { Selection } from '../FilterTable/FilterTableSelection';
import { Actions } from '../../enums/history-key';

const TABLE_NAME = 'history-table';

export const History = () => {
  const { claims } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<HistoryFilters>(DEFAULT_FILTERS);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [list, setList] = useState<ProfileHistory[]>([]);
  const [documentsCount, setDocumentsCount] = useState<number>(1);
  const [selectedItem, setSelectedItem] = useState<ProfileHistory>({
    created: '',
    documentId: '',
    fileName: '',
    signed: '',
    status: DocumentStatus.CANCELED,
  });
  const navigate = useNavigate();
  const { mode, activeItem, onActionClick, onClose } =
    useTableActions<ProfileHistory>();
  const {
    downloadFile,
    getHistory,
    archiveDocument,
    archiveMultipleDocuments,
    cancelDocumentSign,
  } = useDocument();

  const getHistoryList = () => {
    const params = getSearchParams(searchParams) as Props;
    const updatedFilter = !firstLoad
      ? { ...filters, ...getHistoryFilters(params) }
      : filters;
    getHistory({
      ...updatedFilter,
    })
      .then(({ data }: { data: HistoryResponse }) => {
        setList(data.items);
        setDocumentsCount(data.count);
        setFirstLoad(true);
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const onViewHandler = useCallback((item: ProfileHistory) => {
    setSelectedItem(item);
  }, []);

  const oDownloadHandler = useCallback((item: ProfileHistory) => {
    downloadFile(item.documentId, item.fileName).then(() =>
      toast.success('The document has been downloaded.'),
    );
  }, []);

  const onSubmitFilterHandler = useCallback((values: Props) => {
    setFilters({
      ...filters,
      ...getHistoryFilters(values),
    });
  }, []);

  const onResetHandler = useCallback(() => {
    setFilters(DEFAULT_FILTERS);
  }, []);

  const archiveBtn = {
    name: 'archive',
    tooltip: 'Archive Document',
    icon: 'icon-archive',
    onClick: (item: ProfileHistory) => onActionClick(Actions.Archive, item),
  };

  const cancelBtn = {
    name: 'cancel',
    tooltip: 'Cancel Signature',
    icon: 'icon-increase-rotate text-error',
    onClick: (item: ProfileHistory) => onActionClick(Actions.Cancel, item),
  };

  const buttonActions = useMemo(
    () => [
      {
        name: 'view',
        tooltip: 'View  Document',
        icon: 'icon-eye',
        onClick: (item: ProfileHistory) => onViewHandler(item),
      },
      {
        name: 'download',
        tooltip: 'Download Document',
        icon: 'icon-download-key',
        onClick: oDownloadHandler,
      },
    ],
    [],
  );

  const columnsTable: Column[] = useMemo(
    () => [
      ...COLUMNS,
      {
        headerText: 'Actions',
        field: 'action',
        render: (_: RenderValue, record: any) => {
          const buttons = [...buttonActions];
          if (
            DocumentStatus.PENDING?.toLowerCase() !==
            record.status?.toLowerCase()
          ) {
            buttons.push(archiveBtn);
          }
          if (
            DocumentStatus.PENDING?.toLowerCase() ===
            record.status?.toLowerCase()
          ) {
            buttons.push(cancelBtn);
          }
          return (
            <div className="d-flex align-items-center table-text p1">
              <div className="d-flex">
                <ActionButtons item={record} buttons={buttons} />
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  const updateFilterHandler = useCallback(() => {
    const params = getSearchParams(searchParams) as Props;
    setFilters({
      ...filters,
      ...getHistoryFilters(params),
    });
  }, [searchParams, filters]);

  const onArchiveMultipleDocuments = useCallback((payload: unknown) => {
    return archiveMultipleDocuments(
      (payload as Props[]).map((item) => item.documentId),
    )
      .then(() => {
        setFilters(DEFAULT_FILTERS);
        const newParams = updateSearchParams(searchParams, DEFAULT_FILTERS);
        navigate('?' + newParams);
        toast.success('The document(s) has been archived.');
        updateFilterHandler();
      })
      .catch((error: any) => {
        toast.error(
          error?.response?.data?.message ||
            (error?.response?.data as string) ||
            DEFAULT_ERROR_MESSAGE,
        );
      });
  }, []);

  const selection = useMemo(
    () => ({
      name: TABLE_NAME,
      onDelete: (items?: unknown[]) => onArchiveMultipleDocuments(items),
      icon: 'icon-archive',
      text: 'Archive',
    }),
    [],
  );

  const onCancelHandler = useCallback(() => {
    cancelDocumentSign(activeItem?.documentId as string)
      .then(() => {
        toast.success('The document signing has been canceled.');
        updateFilterHandler();
        onClose();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  }, [activeItem]);

  const onArchivehandler = useCallback(() => {
    archiveDocument(activeItem?.documentId as string)
      .then(() => {
        toast.success('The document(s) has been archived.');
        updateFilterHandler();
        onClose();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  }, [activeItem]);

  const onConfirm = useCallback(() => {
    mode === Actions.Cancel && onCancelHandler();
    mode === Actions.Archive && onArchivehandler();
  }, [mode]);

  const onToggleHandler = useCallback(() => {
    onClose();
  }, []);

  useEffect(() => {
    getHistoryList();
  }, [filters, claims?.name]);

  return (
    <div className="d-block section-page my-1 mx-1">
      <div className="header-section d-flex align-items-center">
        <div className="py-2 px-4">
          <p className="open-sans-semibold m-0">
            <i className="align-middle icon-documents me-2"></i>Documents
          </p>
        </div>
      </div>
      <div className="table-container col-12 table-responsive p-3">
        <div className="bg-white inner-section">
          <FilterTable
            items={getFilterItemsByPage(Pages.History)}
            onSubmit={onSubmitFilterHandler}
            onReset={onResetHandler}
            selection={selection as Selection}
          />
          <Table
            name={TABLE_NAME}
            columns={columnsTable}
            data={list}
            pagination={{ totalItems: documentsCount }}
            onSubmit={onSubmitFilterHandler}
            allowRowSelect={true}
            selectKey="documentId"
            activeId={activeItem?.documentId}
          />
          {mode !== Actions.View && (
            <ConfirmModal
              setConfirmationDialog={onToggleHandler}
              deleteHandler={onConfirm}
              config={CONFIG_MODAL_MAP.get(mode)}
              mode={mode}
            />
          )}
        </div>
      </div>
      <HistoryDetails
        historyItem={selectedItem}
        documentHasChanged={getHistoryList}
        page={Pages.History}
      />
    </div>
  );
};
