import React, { useEffect } from 'react';
import { PrivacyPolicyId } from '../../enums';
import { MenuItems } from '../../interfaces';
import { MenuContent } from '../../components/MenuContent/MenuContent';
import styles from './PrivacyPolicy.module.scss';

export const PrivacyPolicy = () => {
  const menuItems: MenuItems[] = [
    {
      label: 'Privacy Policy',
      id: PrivacyPolicyId.PRIVACY_POLICY,
    },
    {
      label: 'Interpretation and Definitions',
      id: PrivacyPolicyId.INTERCEPTION_DEFINITION,
    },
    {
      label: 'Collecting and Using Your Personal Data',
      id: PrivacyPolicyId.PERSONAL_DATA,
    },
    {
      label: 'Types of Data Collected',
      id: PrivacyPolicyId.TYPE_OF_DATA,
    },
    {
      label: 'Types of Data Collected',
      id: PrivacyPolicyId.TYPE_OF_DATA_COLLECTED,
    },
    {
      label: 'Use of Your Personal Data',
      id: PrivacyPolicyId.USE_PERSONAL_DATA,
    },
    {
      label: 'Retention of Your Personal Data',
      id: PrivacyPolicyId.RETENTION_PERSONAL_DATA,
    },
    {
      label: 'Transfer of Your Personal Data',
      id: PrivacyPolicyId.TRANSFER_PERSONAL_DATA,
    },
    {
      label: 'Cookies and Similar Technologies',
      id: PrivacyPolicyId.COOKIES,
    },
    {
      label: 'Links to Other Websites',
      id: PrivacyPolicyId.LINKS,
    },
    {
      label: 'Disclosure of Your Personal Data',
      id: PrivacyPolicyId.DISCLOSURE_PERSONAL_DATA,
    },
    {
      label: 'Security of Your Personal Data',
      id: PrivacyPolicyId.SECURITY_PERSONAL_DATA,
    },
    {
      label: 'Changes to this Privacy Policy',
      id: PrivacyPolicyId.CHANGES_TO_PRIVACY_POLICY,
    },
    {
      label: 'Contact Us',
      id: PrivacyPolicyId.CONTACT_US,
    },
  ];

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <section className={`${styles.privacyPolicy} container mt-5 p-5`}>
      <div className="row">
        <div className="col-9">
          <header>
            <h2 id={PrivacyPolicyId.PRIVACY_POLICY}>Privacy Policy</h2>
          </header>
          <p className="small">Last updated: August 5, 2022</p>

          <p className="mt-4">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.INTERCEPTION_DEFINITION}>
            Interpretation and Definitions
          </h4>
          <h6 className="mt-3">Interpretation</h6>

          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>

          <h6 className="mt-3">Definitions</h6>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <p>
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                &quot;control&quot; means ownership of 50% or more of the
                shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.
              </p>
            </li>
            <li>
              <p>
                <strong>Country</strong> refers to: Zambia
              </p>
            </li>
            <li>
              <p>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cell phone or a digital tablet.
              </p>
            </li>
            <li>
              <p>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </p>
            </li>
            <li>
              <p>
                <strong>Service</strong> refers to the ZamSign Portal.
              </p>
            </li>
            <li>
              <p>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </p>
            </li>
            <li>
              <p>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated using the Service or from the
                Service infrastructure itself (for example, the duration of the
                app usage).
              </p>
            </li>
            <li>
              <p>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
          </ul>

          <h2 className="mt-4" id={PrivacyPolicyId.PERSONAL_DATA}>
            Collecting and Using Your Personal Data
          </h2>
          <h4 className="mt-3" id={PrivacyPolicyId.TYPE_OF_DATA}>
            Types of Data Collected
          </h4>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>
              <p> Email address </p>
            </li>
            <li>
              <p> First/Last name and ID </p>
            </li>
            <li>
              <p> Phone number </p>
            </li>
            <li>
              <p> Address, State, Province, ZIP/Postal code, City </p>
            </li>
            <li>
              <p> Usage Data </p>
            </li>
          </ul>

          <h6 className="mt-3">Usage Data</h6>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>
            Usage Data may include information such as Your Device&apos;s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that You visit, the time and date
            of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <p>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.TYPE_OF_DATA_COLLECTED}>
            Types of Data Collected
          </h4>
          <p>
            While using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission:
          </p>
          <ul>
            <li>
              <p> Information regarding your location </p>
            </li>
            <li>
              <p> Usage statistics </p>
            </li>
          </ul>
          <p>
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company&apos;s servers and/or a Service Provider&apos;s
            server or it may be simply stored on Your device.
          </p>
          <p>
            You can enable or disable access to this information at any time,
            through Your Device settings.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.USE_PERSONAL_DATA}>
            Use of Your Personal Data{' '}
          </h4>
          <p>The Company may use Personal Data for the following purposes: </p>
          <ul>
            <li>
              <p>
                <strong>To provide and maintain our Service</strong>, including
                to monitor the usage of our Service.
              </p>
            </li>
            <li>
              <p>
                <strong>To manage Your Account</strong>: to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </p>
            </li>
            <li>
              <p>
                <strong>For the performance of a contract</strong>: the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </p>
            </li>
            <li>
              <p>
                <strong>To contact You</strong>: To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application&apos;s push
                notifications regarding updates or informative communications
                related to the functionalities, products or contracted services,
                including the security updates, when necessary or reasonable for
                their implementation.
              </p>
            </li>
            <li>
              <p>
                <strong>To manage Your requests</strong>: To attend and manage
                Your requests to Us.
              </p>
            </li>
            <li>
              <p>
                <strong>For business transfers</strong>: We may use Your
                information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or
                transfer of some or all of Our assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding, in which Personal Data held by Us about our Service
                users is among the assets transferred.
              </p>
            </li>
            <li>
              <p>
                <strong>For other purposes</strong>: We may use Your information
                for other purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Service, products,
                services, marketing and your experience.
              </p>
            </li>
          </ul>

          <p>
            We may share Your personal information in the following situations:{' '}
          </p>
          <ul>
            <li>
              <p>
                <strong>With Service Providers</strong>: We may share Your
                personal information with Service Providers to monitor and
                analyze the use of our Service, to contact You.
              </p>
            </li>
            <li>
              <p>
                <strong>With Affiliates</strong>: We may share Your information
                with Our affiliates, in which case we will require those
                affiliates to honor this Privacy Policy. Affiliates include Our
                parent company and any other subsidiaries, joint venture
                partners or other companies that We control or that are under
                common control with Us.
              </p>
            </li>
            <li>
              <p>
                <strong>With business partners</strong>: We may share Your
                information with Our business partners to offer You certain
                products, services or promotions.
              </p>
            </li>
            <li>
              <p>
                <strong>With other users</strong>: when You share personal
                information or otherwise interact in the public areas with other
                users, such information may be viewed by all users and may be
                publicly distributed outside.
              </p>
            </li>
            <li>
              <p>
                <strong>With Your consent</strong>: We may disclose Your
                personal information for any other purpose with Your consent.
              </p>
            </li>
          </ul>

          <h4 className="mt-4" id={PrivacyPolicyId.RETENTION_PERSONAL_DATA}>
            Retention of Your Personal Data{' '}
          </h4>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.TRANSFER_PERSONAL_DATA}>
            Transfer of Your Personal Data{' '}
          </h4>
          <p>
            Your information, including Personal Data, is processed at the
            Company&apos;s operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.COOKIES}>
            Cookies and Similar Technologies{' '}
          </h4>
          <p>
            Cookies are small text files placed on your device to store data
            that can be recalled by a web server in the domain that placed the
            cookie. We use cookies and similar technologies for storing and
            honouring your preferences and settings, enabling you to sign-in,
            providing interest-based advertising, combating fraud, analysing how
            our products perform and fulfilling other legitimate purposes.
          </p>
          <p>
            You have a variety of tools to control the data collected by
            cookies, web beacons, and similar technologies. For example, you can
            use controls in your internet browser to limit how the websites you
            visit are able to use cookies and to withdraw your consent by
            clearing or blocking cookies.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.LINKS}>
            Links to Other Websites
          </h4>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third-party link, You will be
            directed to that third party&apos;s site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third-party sites or
            services.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.DISCLOSURE_PERSONAL_DATA}>
            Disclosure of Your Personal Data
          </h4>
          <h6 className="mt-2">Business Transactions</h6>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>

          <h6 className="mt-2">Law enforcement</h6>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>

          <h6 className="mt-2">Other legal requirements</h6>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul>
            <li>
              <p>Comply with a legal obligation </p>
            </li>
            <li>
              <p>Protect and defend the rights or property of the Company</p>
            </li>
            <li>
              <p>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </p>
            </li>
            <li>
              <p>
                Protect the personal safety of Users of the Service or the
                public
              </p>
            </li>
            <li>
              <p>Protect against legal liability</p>
            </li>
          </ul>

          <h4 className="mt-4" id={PrivacyPolicyId.SECURITY_PERSONAL_DATA}>
            Security of Your Personal Data
          </h4>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <ol>
            <li>
              Our Service is scanned on a regular basis for security holes and
              known vulnerabilities in order to make your visit to our site as
              safe as possible. We are committed to implement appropriate
              technical and other security measures to protect the integrity and
              confidentiality of your information. Your personal information is
              contained behind secured networks and is only accessible by a
              limited number of persons who have special access rights to such
              systems and are required to keep the information confidential.
            </li>
            <li>
              Third parties, like payment gateways, may collect your payment
              details via our website to ensure that you transact securely with
              us. Only the gateway provider will store and process these
              details.
            </li>
            <li>
              Security breach. We will report any security breach to the
              Information Regulator and to the individuals or companies
              involved. If you want to report any concerns about our privacy
              practices or if you suspect any breach regarding your information,
              kindly notify us by sending an email to email@email.com.
            </li>
          </ol>

          <h4 className="mt-4" id={PrivacyPolicyId.CHANGES_TO_PRIVACY_POLICY}>
            Changes to this Privacy Policy
          </h4>
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <h4 className="mt-4" id={PrivacyPolicyId.CONTACT_US}>
            Contact Us
          </h4>
          <p>
            If you have a privacy concern, complaint, or any questions, You can
            contact us:{' '}
          </p>
          <ul>
            <li>
              <p>
                By email:{' '}
                <a href="mailto: zam@gov.zm" className="text-link">
                  zam@gov.zm
                </a>
              </p>
            </li>
            <li>
              <p>
                By visiting this page on our website:{' '}
                <a
                  href="https://www.mycompany.com/contact"
                  target="_blank"
                  className="text-link"
                >
                  {' '}
                  https://www.mycompany.com/contact
                </a>
              </p>
            </li>
            <li>
              <p>
                By phone number:{' '}
                <a href="tel:+260 211253438" className="text-link">
                  +260 211253438
                </a>
              </p>
            </li>
            <li>
              <p>
                By address: 767 Fifth Avenue New York, NY 10153, United States{' '}
              </p>
            </li>
          </ul>
        </div>
        <div className={`${styles.menu} col-3 position-fixed`}>
          <MenuContent menuItems={menuItems} />
        </div>
      </div>
    </section>
  );
};
