import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import SignIcon from '../../assets/images/icons/sign.svg';
import VerifyIcon from '../../assets/images/icons/verify.svg';

import { Slider } from '../../components';
import { routes } from '../../models';

import './Home.scss';

const NAVIGATE_LINKS = [
  {
    imagePath: SignIcon,
    altText: 'Sign logo',
    path: routes.documentSign,
    cssClass: 'text-white nav-link btn btn-primary',
    label: 'E-Sign Document',
  },
  {
    imagePath: VerifyIcon,
    altText: 'Verify logo',
    path: routes.documentVerify,
    cssClass: 'text-white nav-link btn btn-primary',
    label: 'Verify E-Signature',
  },
];

export const Home: FC = memo(() => (
  <>
    <section className="bg d-flex align-items-center pt-5 mt-5 home-page">
      <div className="container d-flex align-items-center">
        <div className="d-flex text-white flex-column flex-lg-row align-items-center row mt-md-0 mt-sm-5 mb-sm-3">
          <div className="col-6">
            <h1>Government Digital Signature Service</h1>
            <p className="mb-0 subtitle">
              E-sign and verify documents online at a glance.
            </p>
          </div>

          <div className="col-6 d-flex flex-column align-items-end align-items-sm-center">
            {NAVIGATE_LINKS.map((link, index) => (
              <div className="py-2 navigation-section" key={index}>
                <Link
                  to={link.path}
                  className="d-flex align-items-center btn btn-primary"
                  key={index}
                >
                  <img src={link.imagePath} alt={link.altText} />
                  <span>{link.label}</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
    <section>
      <Slider />
    </section>
  </>
));
