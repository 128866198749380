import { Message } from '../constants/';

export namespace Validators {
  export const required = {
    validator: (value: string) => {
      return !value || !value.toString().length;
    },
    message: Message.INPUT_REQUIRED,
  };
}
