import { FC, memo } from 'react';
import { Option } from '../../interfaces';

interface SelectProps {
  value: string;
  options: Option[];
  onChange: (e: React.BaseSyntheticEvent) => void;
  className?: string;
}

export const Select: FC<SelectProps> = memo(
  ({ value, options, onChange, className = '' }) => {
    return (
      <select className={`form-select ${className}`} onChange={onChange}>
        {options.map((option, index) => (
          <option
            selected={value === option.value}
            key={`option-${index}`}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    );
  },
);
