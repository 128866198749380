import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface FeedbackDialogProps {
  onClose: () => void;
  title?: string;
}

export const FeedbackDialog = ({ onClose, title }: FeedbackDialogProps) => {
  const dialogPortal = document.getElementById('root') as HTMLElement;
  const formRef = useRef<HTMLFormElement>(null);
  const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
    if ((e.key || e.code) === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  });

  const feedbackTemplate = (
    <div
      className="modal d-flex justify-content-center align-items-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="modal-content w-40 p-2"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header d-flex justify-content-center border-0">
          <h3 className="modal-title text-primary">{title}</h3>
        </div>
        <form className="row g-0" ref={formRef} method="post">
          <div className="modal-body">
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Name, Surname"
              />
            </div>
            <div className="form-group mb-3">
              <input
                type="email"
                className="form-control"
                id="feedbackEmail"
                placeholder="Email"
              />
            </div>
            <div className="form-group mb-3">
              <select className="form-select text-black">
                <option className="text-description">Strong suit</option>
                <option>Weak suit</option>
              </select>
            </div>
            <div className="form-group mb-3">
              <textarea
                className="form-control text-black"
                rows={1}
                placeholder="Please leave a comment"
              />
            </div>
            <div className="form-group border-dashed p-3" id="fileInputGroup">
              <div className="bg-grey-2 p-3">
                <label
                  className="d-flex w-100 cursor-pointer"
                  htmlFor="formFile"
                >
                  <span className="text-primary semi-bold">
                    <i className="icon-plus me-2 custom-fs-3" />
                    Attach the file
                  </span>
                  <input className="d-none" type="file" id="formFile" />
                  <div className="vr mx-5"></div>
                  <span className="text-description">
                    or drag and drop here
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between p-3 py-4 text-break">
            <button
              onClick={onClose}
              className="btn btn-outline-primary flex-fill me-4"
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary flex-fill">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return ReactDOM.createPortal(feedbackTemplate, dialogPortal);
};
