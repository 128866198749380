import { useMemo } from 'react';
import { DocumentType } from '../../enums';

import styles from './Stepper.module.scss';

const STEPS_MAP = new Map<DocumentType, { id: string; title: string }[]>([
  [
    DocumentType.SIGN,
    [
      {
        id: 'first',
        title: 'Upload Document',
      },
      {
        id: 'second',
        title: 'Specify Position of Signature',
      },
      {
        id: 'third',
        title: 'Confirm Document Signing',
      },
      {
        id: 'completed',
        title: 'View Signed Document',
      },
    ],
  ],
  [
    DocumentType.VERIFY,
    [
      {
        id: 'first',
        title: 'Verify Document',
      },
      {
        id: 'completed',
        title: 'Document Status',
      },
    ],
  ],
]);

interface StepperProps {
  step: number;
  section: string;
}

export const Stepper = ({ step, section }: StepperProps) => {
  const steps = useMemo(
    () => STEPS_MAP.get(section as DocumentType),
    [section],
  );
  const memoizedSteps = useMemo(
    () =>
      steps?.map((item, index) => {
        const active = index === step ? 'active bg-white text-primary' : '';
        const completed = index < step ? 'completed' : '';
        const iconClass =
          step >= index ? 'btn-primary' : 'btn-outline-primary border-2';
        const done = step > index ? 'done bg-white text-primary' : '';

        return (
          <div
            className="d-inline-block my-1 align-items-center stepper-container"
            key={item.id}
          >
            <div
              className={`d-flex ps-1 py-1 pe-4 ${active} ${completed} ${done}`}
            >
              <div className="d-flex flex-column align-items-center me-2">
                <div
                  className={`${
                    styles.stepIcon
                  } rounded-circle d-flex step-button justify-content-center align-items-center text-primary fw-bold fs-6 ${iconClass} ${
                    completed || active ? 'bg-primary text-white' : 'disabled'
                  } text-center`}
                >
                  {index + 1}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <p className="p5-400 text-dark m-0">{item.title}</p>
              </div>
            </div>
          </div>
        );
      }),
    [steps, step],
  );

  return <div className={`${styles.stepper} px-2`}>{memoizedSteps}</div>;
};
