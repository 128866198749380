import { DocumentStatus, Actions } from './enums';
import { Column, ConfigModal, Option } from './interfaces';

export const THEMES = {
  lightZam: '/themes/zam-light.css',
  darkZam: '/themes/dark-zam.css',
  lightDotgov: '/themes/dotgov-light.css',
  darkDotGov: '/themes/dark-dotgov.css',
};

export const ACCEPTABLE_SIGNATURE_IMAGE_FORMAT = 'image/jpeg,image/png';

export const DATE_FORMAT = 'MMM DD YYYY HH:mm:ss';
export const LABEL_MAP = new Map<DocumentStatus, string>([
  [DocumentStatus.SIGNED, 'Signed'],
  [DocumentStatus.PENDING, 'Pending Signature'],
  [DocumentStatus.CANCELED, 'Cancelled'],
]);

export const DOTGOV_FOOTER_TEXT =
  'DotGov Sign - Government Digital Signature Service. 2024, All Rights Reserved.';

export const ZAM_FOOTER_TEXT =
  'Zam Sign - Government Digital Signature Service. 2024, All Rights Reserved.';

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please try again later';

export const MAX_FILE_SIZE_ERROR = 'The maximum file size allowed is 2 MB.';

export const FILE_TYPE_ERROR = 'Only PNG and JPG file types are allowed';

export const DEFAULT_PAGE_SIZE = '10';

export const DATE_RANGE_DELIMITER = ' - ';

export const PAGE_KEY = 'page';

export const PAGE_SIZE_KEY = 'count';

export const PAGINATION_LIMIT = 5;

export const ITEMS_PER_PAGE_OPTIONS: Option[] = ['10', '20', '30', '50'].map(
  (x) => ({
    label: x,
    value: x,
  }),
);

export const DEFAULT_FILTERS = {
  count: 10,
  page: 1,
};

export const TABLE_COUNTER_COLUMN: Column = {
  headerText: '#',
  field: 'rowCounter',
  width: '36px',
  render: (_, __, index) => <div>{++index!}</div>,
};

export const ICON_NAME_MAP = new Map<string | null, string>([
  [null, 'icon-default-sort'],
  ['false', 'icon-arrow-down-sort'],
  ['true', 'icon-arrow-up-sort'],
]);

export const CONFIG_MODAL_MAP = new Map<Actions, ConfigModal>([
  [
    Actions.Cancel,
    {
      title: 'Are you sure you want to cancel the document signing?',
      confirmButton: {
        text: 'Cancel',
        icon: 'icon-cancel',
      },
      cancelButton: {
        text: 'Close',
      },
    },
  ],
  [
    Actions.Archive,
    {
      title: 'Are you sure you want to archive the document?',
      confirmButton: {
        text: 'Archive',
        icon: 'icon-archive',
      },
      cancelButton: {
        text: 'Cancel',
      },
    },
  ],
  [
    Actions.Delete,
    {
      title: 'Are you sure you want to delete the document?',
      subtitle: 'You will not be able to recover the document',
      confirmButton: {
        text: 'Delete',
        icon: 'icon-delete',
      },
      cancelButton: {
        text: 'Cancel',
      },
    },
  ],
  [
    Actions.Restore,
    {
      title: 'Are you sure you want to restore the document?',
      confirmButton: {
        text: 'Restore',
        icon: 'icon-restore',
      },
      cancelButton: {
        text: 'Cancel',
      },
    },
  ],
]);
