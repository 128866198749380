import React, { FC, useCallback, memo } from 'react';
import { default as BsTooltip } from 'react-bootstrap/Tooltip';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { TooltipDirection } from '../../enums/history-key';

import styles from './Tooltip.module.scss';

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode | (() => React.ReactNode);
  direction: TooltipDirection;
  delay?: number;
  show?: boolean;
  className?: string;
}

export const Tooltip: FC<TooltipProps> = memo(
  ({ children, content, direction, show, delay = 400, className = '' }) => {
    const renderTooltip = useCallback(
      (props: OverlayInjectedProps) => (
        <BsTooltip {...props}>
          <div className={className}>
            {typeof content === 'function' ? content() : content}
          </div>
        </BsTooltip>
      ),
      [content],
    );

    return (
      <OverlayTrigger
        placement={direction}
        delay={{ show: delay, hide: 0 }}
        overlay={renderTooltip}
        show={show}
      >
        <div className={`${styles.tooltipWrapper} ms-2`}>{children}</div>
      </OverlayTrigger>
    );
  },
);
