import {
  FC,
  forwardRef,
  useCallback,
  memo,
  Ref,
  BaseSyntheticEvent,
  RefObject,
  useState,
  useEffect,
} from 'react';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { FieldInputProps, FormikHelpers } from 'formik/dist/types';

import { FormField, InputOnChange, Props } from '../../interfaces';
import { runIfFn, validate } from '../../utils/utils';
import { Validators } from '../../utils';

interface DynamicFormProps {
  name?: string;
  inputs: FormField[];
  initialValues?: object;
  onSubmit?: (values: object, actions: FormikHelpers<object>) => void;
  onChange?: (values: Props) => void;
  onSave?: (values: Props) => void;
  ref?: RefObject<FormikProps<object>>;
}
export const DynamicForm: FC<DynamicFormProps> = memo(
  forwardRef(
    (
      {
        inputs: inputsConfig = [],
        initialValues = {},
        onSubmit,
        onChange,
        onSave,
        name = 'field',
      },
      ref,
    ) => {
      const [inputs, setInputs] = useState<FormField[]>(inputsConfig);

      const handleSubmit = useCallback(
        (values: object, actions: FormikHelpers<object>) => {
          onSubmit?.(values, actions);
        },
        [onSubmit],
      );

      const handleChange = useCallback(
        (values: object) => (e: BaseSyntheticEvent) => {
          onChange?.({
            ...values,
            [e.target.name]: e.target.value,
          });
        },
        [],
      );

      const handleInputChange = useCallback(
        (
            field: FieldInputProps<object>,
            form: FormikProps<object>,
            inputOnChange?: (args: InputOnChange) => void,
          ) =>
          (e: BaseSyntheticEvent | string) => {
            const event = e as BaseSyntheticEvent;
            const changeContext = {
              inputs,
              setInputs,
              form,
            };
            if (event?.target) {
              const value =
                event.target?.type === 'checkbox'
                  ? event.target?.checked
                  : event.target?.value;
              const label = event?.target?.label;
              field.onChange(e);
              inputOnChange?.({
                ...changeContext,
                value,
                label,
                event,
              });
            } else {
              inputOnChange?.({
                ...changeContext,
                value: e as string,
              });
            }
          },
        [inputs],
      );

      useEffect(() => setInputs(inputsConfig), [inputsConfig]);

      return (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          innerRef={ref as Ref<FormikProps<object>>}
        >
          {({ handleSubmit: handleSubmitForm, values }) => (
            <Form
              onSubmit={(args: any) => {
                onSave?.(values);
                handleSubmitForm(args);
              }}
              style={{ width: '100%', height: '100%' }}
              onChange={handleChange(values)}
              className="base-form"
            >
              <div className="d-flex">
                {inputs
                  .filter((input) => !runIfFn(input.props?.hidden, values))
                  .map((input, i: number) => (
                    <div
                      key={`form-field-${input.name}-${name}-${i}`}
                      className="py-2 pe-2"
                    >
                      <Field
                        name={input.name}
                        validate={input.rules && validate(input, values)}
                      >
                        {({ field, form }: FieldProps) => (
                          <div>
                            <label
                              id={`label-${field.name}-${i}`}
                              htmlFor={`${name}-${field.name}-${i}`}
                              hidden={!input?.label}
                              className="form-label"
                            >
                              {input?.label ?? ''}
                              {input.rules?.includes(Validators.required) && (
                                <span>*</span>
                              )}
                            </label>
                            <input.type
                              error={form.errors[field.name] as string}
                              id={`${name}-${field.name}-${i}`}
                              formname={name}
                              {...field}
                              {...input?.props}
                              onChange={handleInputChange(
                                field,
                                form,
                                input?.props?.onChange,
                              )}
                              placeholder={input?.props?.placeholder}
                              value={field.value ?? ''}
                            />
                            {!input?.noErrorMessage && (
                              <div>{form.errors[field.name] as string}</div>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  ))}
              </div>
            </Form>
          )}
        </Formik>
      );
    },
  ),
);
