import { FC, memo, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { Modal } from '../';
import { ConfigModal } from '../../interfaces';
import { Actions } from '../../enums';

interface ConfirmModalProps {
  setConfirmationDialog: () => void;
  deleteHandler: () => void;
  config?: ConfigModal;
  mode?: Actions;
}

export const ConfirmModal: FC<ConfirmModalProps> = memo(
  ({ setConfirmationDialog, deleteHandler, config, mode }) => {
    const confirmClass = useMemo(
      () =>
        [Actions.Delete, Actions.Cancel].includes(mode as Actions)
          ? 'btn-danger delete-button'
          : 'btn-primary modal-button',
      [mode],
    );

    const titleClass = useMemo(
      () =>
        [Actions.Delete, Actions.Cancel].includes(mode as Actions)
          ? 'text-error'
          : '',
      [mode],
    );

    return createPortal(
      <Modal
        title={config?.title}
        titleClass={titleClass}
        subTitle={config?.subtitle}
      >
        <div className="d-flex py-3 justify-content-between">
          <div className="d-inline-flex w-100">
            <button
              type="button"
              className="d-inline-flex justify-content-center btn btn-outline-primary cancel-button"
              onClick={setConfirmationDialog}
            >
              {config?.cancelButton.icon && (
                <i className={`${config.cancelButton?.icon} mx-2`} />
              )}
              {config?.cancelButton.text}
            </button>
          </div>
          <div className="d-inline-flex w-100">
            <button
              type="button"
              className={`d-inline-flex justify-content-center align-items-center btn text-white ${confirmClass}`}
              onClick={deleteHandler}
            >
              {config?.confirmButton.icon && (
                <i className={`${config.confirmButton.icon} mx-2`} />
              )}
              {config?.confirmButton.text}
            </button>
          </div>
        </div>
      </Modal>,
      document.querySelector('#root') as Element,
    );
  },
);
