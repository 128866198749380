export enum StatusStep {
  FIRST = 'first',
  UPLOADED = 'uploaded',
  SIGN = 'sign',
  PROGRESS = 'progress',
  SUCCESS = 'success',
  ERROR = 'error',
  VERIFY_UPLOAD = 'verifyUpload',
  TIME_OUT = 'timeOut',
}
