import { useEffect, DependencyList } from 'react';

export const useDebounce = (
  fn: () => void,
  waitTime: number,
  deps?: DependencyList,
) => {
  useEffect(() => {
    const t = setTimeout(() => {
      return fn();
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, [deps]);
};
