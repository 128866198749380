import { DocumentStatus, FieldKeys, Pages } from '../enums';
import {
  Column,
  FormField,
  Props,
  RenderValue,
  StatusInfo,
} from '../interfaces';
import { Input } from '../components/Input/Input';
import { MultiSelect } from '../components/MultiSelect/MultiSelect';
import { Status } from '../components/Status/Status';
import { DatePickerRange } from '../components/DatePickerRange/DatePickerRange';
import { capitalize, formatDateAndTime, transformFilterDate } from './utils';

export const getHistoryFilters = (values: Props) => {
  const { created, name, signed, statuses, page, count, sortingField, asc } =
    values;
  const filters: Props = {};

  if (name) filters['name'] = name;
  if (page) filters['page'] = page;
  if (count) filters['count'] = count;
  if (statuses) filters['statuses'] = statuses.split(',');

  if (signed?.endsWith(' - ')) filters['signedStart'] = signed.slice(0, -3);
  else if (signed) {
    const arr = signed.split(' - ');
    filters['signedStart'] = arr[0];
    filters['signedEnd'] = arr[1];
  }
  if (created?.endsWith(' - ')) filters['createdStart'] = created.slice(0, -3);
  else if (created) {
    const arr = created.split(' - ');
    filters['createdStart'] = arr[0];
    filters['createdEnd'] = arr[1];
  }
  if (sortingField) {
    filters['sortingField'] = sortingField;
  }
  if (asc) {
    filters['asc'] = asc;
  }
  return filters;
};

const DOCUMENT_STATUS_MAP = new Map<
  DocumentStatus,
  { text: DocumentStatus.SIGNED | string; icon: string; className: string }
>([
  [
    DocumentStatus.SIGNED,
    {
      text: DocumentStatus.SIGNED,
      icon: 'icon-status-signed',
      className: 'text-success',
    },
  ],
  [
    DocumentStatus.PENDING,
    {
      text: 'Pending Signature',
      icon: 'icon-status-pending',
      className: 'text-warning',
    },
  ],
  [
    DocumentStatus.CANCELED,
    {
      text: DocumentStatus.CANCELED,
      icon: 'icon-status-cancelled',
      className: 'text-error',
    },
  ],
]);

export const COLUMNS: Column[] = [
  {
    headerText: 'Name',
    field: 'fileName',
    sort: {
      field: capitalize(FieldKeys.FileName),
      valueKey: FieldKeys.Asc,
    },
  },
  {
    headerText: 'Status',
    field: 'status',
    render: (value: any) => (
      <div className="d-flex align-items-center table-text p-1">
        <Status
          config={
            DOCUMENT_STATUS_MAP.get(value as DocumentStatus) as StatusInfo
          }
        />
      </div>
    ),
  },
  {
    headerText: 'Date Created',
    field: 'created',
    render: (value: RenderValue) => (
      <div className="d-flex align-items-center table-text p-1">
        {formatDateAndTime(value as string)}
      </div>
    ),
    sort: {
      field: capitalize(FieldKeys.Created),
      valueKey: FieldKeys.Asc,
    },
  },
  {
    headerText: 'Date Signed',
    field: 'signed',
    render: (value: RenderValue) => (
      <div className="d-flex align-items-center table-text p-1">
        {formatDateAndTime(value as string)}
      </div>
    ),
    sort: {
      field: capitalize(FieldKeys.SignedAt),
      valueKey: FieldKeys.Asc,
    },
  },
];

export const getFilterItemsByPage = (page: Pages) => [
  {
    name: FieldKeys.Name,
    type: Input,
    label: 'Name',
    props: {
      placeholder: 'Name',
    },
  },
  {
    type: DatePickerRange,
    name: FieldKeys.Created,
    label: 'Date Created',
    props: {
      placeholder: 'Select Date Range',
      transformValue: transformFilterDate,
    },
  },
  {
    type: DatePickerRange,
    name: FieldKeys.Signed,
    label: 'Date Signed',
    props: {
      placeholder: 'Select Date Range',
      transformValue: transformFilterDate,
    },
  },
  {
    type: MultiSelect,
    name: FieldKeys.Status,
    label: 'Status',
    props: {
      placeholder: 'Status',
      options:
        page === Pages.DocumentsArchive
          ? Object.values(DocumentStatus)
              .filter((status) => status !== DocumentStatus.PENDING)
              .map((status) => ({
                label: status,
                value: status,
              }))
          : Object.values(DocumentStatus).map((status) => ({
              label:
                status === DocumentStatus.PENDING
                  ? 'Pending Signature'
                  : status,
              value: status,
            })),
    },
  },
];
