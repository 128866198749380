import { BaseSyntheticEvent, FC, memo } from 'react';
import { InputType } from '../../enums';

interface InputProps {
  name: string;
  value: string;
  type: InputType;
  onChange: (event: BaseSyntheticEvent | object) => void;
  onClick?: () => void | undefined;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
}

export const Input: FC<InputProps> = memo(
  ({
    name,
    type,
    value,
    onChange,
    disabled,
    required,
    className = '',
    placeholder = '',
  }) => {
    return (
      <div>
        <input
          id={name}
          className={`form-control ${className}`}
          aria-label={name}
          data-testid={name}
          tabIndex={0}
          type={type}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          disabled={!!disabled}
          required={!!required}
        />
      </div>
    );
  },
);
