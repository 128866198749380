import { StatusStep } from '../../enums';
import { ButtonSetting } from '../../interfaces';
import { StatusConfig } from '../../interfaces/status-config';
import { DocumentPlaceholder } from '../DocumentPlaceholder/DocumentPlaceholder';
import { StatusDetails } from '../StatusDetails/StatusDetails';
import SuccessSignIcon from '../../assets/images/icons/success-sign-icon.svg';
import FailureSignIcon from '../../assets/images/icons/failure-sign-icon.svg';
import { Spinner } from '../Spinner/Spinner';

export const titleMap = new Map<StatusStep, string>([
  [StatusStep.FIRST, 'Upload the PDF file for signature verification'],
  [StatusStep.VERIFY_UPLOAD, 'Verification of the document’s signing status'],
  [StatusStep.UPLOADED, 'Preview the details of the document’s signing status'],
  [StatusStep.ERROR, 'Preview the details of the document’s signing status'],
  [StatusStep.SUCCESS, 'Preview the details of the document’s signing status'],
]);

const VERIFY_ERROR = {
  TITLE: 'No signature(s) found',
};

export const buttonsMap = new Map<
  StatusStep,
  ({
    handleBackBtn,
    onChangeStep,
  }: {
    handleBackBtn: () => void;
    onChangeStep: (status: StatusStep, step: number) => void;
    disabled: boolean;
    verifySignatureHandler: () => void;
  }) => ButtonSetting[]
>([
  [
    StatusStep.FIRST,
    ({ handleBackBtn, disabled, verifySignatureHandler }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          disabled,
          label: 'Verify',
          class: 'btn-primary',
          onClick: verifySignatureHandler,
        },
      ];
    },
  ],
  [
    StatusStep.VERIFY_UPLOAD,
    ({ handleBackBtn }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: handleBackBtn,
        },
        {
          disabled: true,
          label: 'Verify',
          class: 'btn-primary',
        },
      ];
    },
  ],
  [
    StatusStep.SUCCESS,
    ({ onChangeStep }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: () => onChangeStep(StatusStep.FIRST, 0),
        },
      ];
    },
  ],
  [
    StatusStep.ERROR,
    ({ onChangeStep }) => {
      return [
        {
          label: 'Back',
          iconClass: 'icon-arrow-left',
          class: 'btn-outline-primary',
          onClick: () => onChangeStep(StatusStep.FIRST, 0),
        },
      ];
    },
  ],
]);

export const statusMap = new Map<StatusStep, StatusConfig>([
  [StatusStep.UPLOADED, { Component: DocumentPlaceholder }],
  [StatusStep.SIGN, { Component: DocumentPlaceholder }],
  [
    StatusStep.SUCCESS,
    {
      Component: StatusDetails,
      img: {
        url: SuccessSignIcon,
        alt: 'SuccessSignIcon',
      },
      title: 'The file was successfully signed',
      showDetails: true,
    },
  ],
  [
    StatusStep.ERROR,
    {
      Component: StatusDetails,
      img: {
        url: FailureSignIcon,
        alt: 'FailureSignIcon',
      },
      title: VERIFY_ERROR.TITLE,
    },
  ],
  [
    StatusStep.VERIFY_UPLOAD,
    {
      Component: StatusDetails,
      SpinnerComponent: Spinner,
      description: 'Verification is in progress... It might take some time',
    },
  ],
  [
    StatusStep.SUCCESS,
    {
      Component: StatusDetails,
      showDetails: true,
    },
  ],
]);
