import { stringify } from 'query-string';
import { saveAs } from 'file-saver';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  DocumentInfoResponse,
  HistoryFilters,
  HistoryResponse,
  UploadRequest,
  UploadResponse,
  VerifyDocumentRequest,
  VerifyResponse,
} from '../interfaces';
import Axios from '../utils/axios-config';
import { VerifyDocumentResponse } from '../interfaces/api-response';

export const useDocument = () => {
  const uploadDocument = async (
    payload: UploadRequest,
  ): Promise<{ data: UploadResponse }> => {
    return await Axios.post<UploadResponse>('pdf/upload', payload);
  };

  const getDocumentById = (id: string): Promise<AxiosResponse> => {
    return Axios.get(`/pdf/${id}`, { responseType: 'blob' });
  };

  const downloadFile = async (id: string, fileName: string): Promise<void> => {
    return getDocumentById(id).then(({ data }) => saveAs(data, fileName));
  };

  const verifySignature = (
    payload: VerifyDocumentRequest,
    config?: AxiosRequestConfig,
  ): Promise<VerifyResponse> => {
    return Axios.post<VerifyDocumentResponse>('/pdf/verifySignature', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    });
  };

  const cancelDocumentSign = (id: string) => {
    return Axios.delete(`/pdf/${id}/cancel`);
  };

  const getDocumentInfo = (
    id: string,
  ): Promise<{ data: DocumentInfoResponse }> => {
    return Axios.get(`/pdf/${id}/documentInfo`);
  };

  const getHistory = (
    filters: HistoryFilters,
  ): Promise<{ data: HistoryResponse }> => {
    return Axios.get(`/pdf/history?${stringify(filters)}`);
  };

  const renewOtpCode = (id: string): Promise<{ data: string }> => {
    return Axios.get(`/pdf/${id}/otpRenew`);
  };

  const archiveDocument = (id: string) => {
    return Axios.patch(`/pdf/${id}/archive?isArchived=true`);
  };

  const restoreDocument = (id: string) => {
    return Axios.patch(`/pdf/${id}/archive?isArchived=false`);
  };

  const archiveMultipleDocuments = (documentIds: string[]) => {
    return Axios.patch('/pdf/archive/batch', documentIds);
  };

  const deleteMultipleDocuments = (documentIds: string[]) => {
    return Axios.delete('/pdf', { data: documentIds });
  };

  const getCertificatesIssuer = (): Promise<{ data: { issuer: string } }> => {
    return Axios.get('/certificates/issuer');
  };

  return {
    uploadDocument,
    getDocumentById,
    downloadFile,
    verifySignature,
    cancelDocumentSign,
    getDocumentInfo,
    getHistory,
    renewOtpCode,
    archiveDocument,
    restoreDocument,
    archiveMultipleDocuments,
    deleteMultipleDocuments,
    getCertificatesIssuer,
  };
};
