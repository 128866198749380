import {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import Draggable, { DraggableEvent } from 'react-draggable';

import { Buttons } from '../Buttons/Buttons';
import {
  Checkbox,
  Delimiter,
  DynamicForm,
  UploadSignatureDialog,
  Values,
} from '../index';
import {
  FormField,
  InputOnChange,
  SignatureInfo,
  UploadRequest,
} from '../../interfaces';
import { INITIAL_VALUE_ADDITIONAL, INITIAL_VALUE_SIGNATURE } from './constants';

import DragAndDropIcon from '../../assets/images/icons/drag-and-drop-icon.svg';

import styles from './DocumentPlaceholder.module.scss';

interface DocumentPlaceholderProps {
  handleDrag: (event: DraggableEvent) => void;
  setUploadPayload: Dispatch<SetStateAction<UploadRequest>>;
  uploadPayload: UploadRequest;
  signatureInfo: SignatureInfo;
  styleSignature: boolean;
  setStyleSignature: Dispatch<SetStateAction<boolean>>;
  checkOverlapBoxSignature?: () => void;
}

export const DocumentPlaceholder: FC<DocumentPlaceholderProps> = memo(
  ({
    handleDrag,
    setUploadPayload,
    uploadPayload,
    signatureInfo,
    styleSignature,
    setStyleSignature,
    checkOverlapBoxSignature,
  }) => {
    const nodeRef = useRef(null);
    const imgRef = useRef<HTMLImageElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const [showUploadSignatureDialog, setUploadSignatureDialog] =
      useState(false);
    const [deleted, setDeleted] = useState<boolean>(false);

    const bodyContainer = useMemo(() => document.querySelector('body'), []);

    const onShowUploadHandler = useCallback(() => {
      (bodyContainer?.style as CSSStyleDeclaration).overflowY = 'hidden';
      setUploadSignatureDialog(true);
      setDeleted(false);
    }, [deleted]);

    const onDeleteImageHandler = useCallback(() => {
      setUploadPayload((currentState) => ({
        ...currentState,
        signatureInfo: {
          ...currentState.signatureInfo,
          image: '',
        },
      }));
      setDeleted(true);
    }, [uploadPayload]);

    const uploadButton = useMemo(
      () => ({
        label: uploadPayload.signatureInfo.image
          ? 'Replace Signature Image'
          : 'Upload Signature Image',
        iconClass: 'icon-upload-key',
        class: 'btn-outline-primary',
        onClick: onShowUploadHandler,
      }),
      [uploadPayload],
    );

    const deleteButton = useMemo(
      () => ({
        label: 'Delete image',
        iconClass: 'icon-delete-fill',
        class: 'btn-outline-primary btn-attention',
        onClick: onDeleteImageHandler,
      }),
      [uploadPayload],
    );

    const buttons = useMemo(
      () =>
        uploadPayload.signatureInfo.image
          ? [uploadButton, deleteButton]
          : [uploadButton],
      [uploadPayload, uploadButton, deleteButton],
    );

    const onChangeTextSignature = useCallback(
      ({ event }: InputOnChange) => {
        setUploadPayload((currentState) => ({
          ...currentState,
          signatureInfo: {
            ...currentState.signatureInfo,
            writeText: event?.target.checked,
          },
        }));
        setStyleSignature(false);
      },
      [uploadPayload],
    );

    const onChangeAdditionalInfo = useCallback(
      ({ value }: InputOnChange) => {
        setUploadPayload((currentState) => ({
          ...currentState,
          signatureInfo: {
            ...currentState.signatureInfo,
            additionalTextFields: value.split(','),
          },
        }));
      },
      [uploadPayload],
    );

    const onStopDragHandler = useCallback(
      () => (event: DraggableEvent) => {
        handleDrag(event);
        const overlaped = checkOverlapBoxSignature?.();
        !overlaped && (nodeRef.current as any).setState({ x: 0, y: 0 });
      },
      [],
    );

    const noSignature = useMemo(
      () =>
        uploadPayload.signatureInfo.image === '' &&
        !uploadPayload.signatureInfo.writeText,
      [uploadPayload],
    );

    const formFieldsSignature: FormField[] = useMemo(
      () => [
        {
          type: Checkbox,
          name: 'textSignature',
          props: {
            type: 'checkbox',
            checked: true,
            label: 'Use signature text',
            labelClass: 'ps-3',
            name: 'textSignature',
            onChange: onChangeTextSignature,
          },
        },
      ],
      [uploadPayload, onChangeTextSignature],
    );

    const formFieldsAdditional: FormField[] = useMemo(
      () => [
        {
          type: Values,
          name: 'additionalFields',
          props: {
            placeholder: 'Value',
            hidden: !uploadPayload.signatureInfo.writeText,
            name: 'additionalFields',
            additionalInformation: {
              title: 'Additional Information',
              text: 'For more details, you can add additional information to your signature.',
            },
            onChange: onChangeAdditionalInfo,
          },
        },
      ],
      [uploadPayload, onChangeAdditionalInfo],
    );

    const signatureInfoBox = useMemo(
      () => (
        <>
          <p className="custom-fs-8 mb-0 helvetica-regular">
            Digitally signed by: {signatureInfo.signedBy}
          </p>
          <p className="custom-fs-8 mb-0 helvetica-regular">
            Issued by: {signatureInfo.issuer}
          </p>
          <p className="custom-fs-8 mb-0 helvetica-regular">
            Location: {signatureInfo.location}
          </p>
          <p className="custom-fs-8 mb-0 helvetica-regular">
            Date: {signatureInfo.date}
          </p>
        </>
      ),
      [signatureInfo],
    );

    const additionalInfoBox = useMemo(
      () =>
        uploadPayload?.signatureInfo?.additionalTextFields.map(
          (item, index) => (
            <p
              key={`additional-${index}`}
              className="custom-fs-8 mb-0 helvetica-regular text-break"
            >
              {item}
            </p>
          ),
        ),
      [uploadPayload],
    );

    return (
      <div className="ms-5">
        <div className="py-4 row" id="uploadBox">
          <Buttons buttons={buttons} />
        </div>
        {!deleted && (
          <UploadSignatureDialog
            title={'Upload Signature Image'}
            imgRef={imgRef}
            canvasRef={canvasRef}
            showDialog={showUploadSignatureDialog}
            setUploadPayload={setUploadPayload}
            onClose={() => {
              setUploadSignatureDialog((state) => !state);
              (bodyContainer?.style as CSSStyleDeclaration).overflowY = 'auto';
            }}
          />
        )}
        <div>
          <DynamicForm
            inputs={formFieldsSignature}
            initialValues={INITIAL_VALUE_SIGNATURE}
          />
          {!noSignature && (
            <div className="d-flex justify-content-center align-items-center">
              <p className="custom-fs-2-1 mb-0">
                Drag and drop this signature into the uploaded document, place
                the seal at the bottom of the document (preferably) or in a
                place with the least amount of information.
              </p>
            </div>
          )}
          {noSignature && (
            <div>
              <Delimiter className="my-3" />
              <h5>Attention</h5>
              <p className="custom-fs-2-1">
                You have not selected the signature image or the text to be
                displayed on the document. The document can still be signed,
                without any visible widget.
              </p>
            </div>
          )}
        </div>
        {!noSignature && (
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <img
                src={DragAndDropIcon}
                alt="Drag and drop icon"
                className="img-fluid me-4"
              />
            </div>
            <div
              id="boxSignature"
              className={`signature my-4 ${styles.withImage}`}
            >
              <Draggable
                ref={nodeRef}
                onStop={onStopDragHandler()}
                onDrag={(event) => handleDrag(event)}
                defaultClassName="cursor-pointer"
              >
                <div>
                  {uploadPayload.signatureInfo.image && (
                    <div
                      className={`${styles.signImage} ${
                        !styleSignature
                          ? 'default-sign-image'
                          : styles.noStyleSignImage
                      } row justify-content-center p-0 m-0`}
                      id="signImage"
                    >
                      <div
                        className={`${
                          uploadPayload.signatureInfo.writeText
                            ? 'col-3'
                            : 'col-12'
                        } p-0 d-flex justify-content-center align-items-center`}
                        style={{
                          maxWidth: 250,
                          maxHeight: 95,
                          overflow: 'hidden',
                        }}
                      >
                        <img
                          className={`w-100 h-100 ${styles.imageSignature} ${
                            uploadPayload.signatureInfo.writeText
                              ? styles.imageSignaturePadding
                              : ''
                          }`}
                          src={`data:image/png;base64, ${uploadPayload.signatureInfo.image}`}
                        />
                      </div>
                      {uploadPayload.signatureInfo.writeText && (
                        <div className="col-9 p-0 d-flex">
                          <div>
                            {signatureInfoBox}
                            {additionalInfoBox}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {uploadPayload.signatureInfo.image === '' && (
                    <div>
                      <div
                        className={`${styles.signImage} ${
                          !styleSignature
                            ? 'default-sign-image'
                            : styles.noStyleSignImage
                        }`}
                        id="signImage"
                      >
                        {signatureInfoBox}
                        {additionalInfoBox}
                      </div>
                    </div>
                  )}
                </div>
              </Draggable>
            </div>
          </div>
        )}
        {uploadPayload.signatureInfo.writeText && <Delimiter />}
        <DynamicForm
          inputs={formFieldsAdditional}
          initialValues={INITIAL_VALUE_ADDITIONAL}
        />
      </div>
    );
  },
);
