import {
  Dispatch,
  FC,
  LegacyRef,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Document, Page } from 'react-pdf';

import { FileType } from '../../enums/file-type';

import styles from './DocumentView.module.scss';

interface DocumentViewProps {
  file: FileType;
  onLoadError?: (e: Error) => void;
  setPage?: Dispatch<SetStateAction<number>>;
}

const REACT_PDF_CLASS_SELECTOR = '.react-pdf__Document';
const REACT_PDF_PAGE_CLASS_SELECTOR = '.react-pdf__Page';
const PAGE_HEIGHT = 860;

export const DocumentView: FC<DocumentViewProps> = memo(
  ({ file, onLoadError, setPage }) => {
    const [sumOfPages, setSumOfPages] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const documentRef = useRef<LegacyRef<HTMLDivElement> | undefined>();
    const reactPdfContainer: Element = document.querySelector(
      REACT_PDF_CLASS_SELECTOR,
    ) as Element;

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
      setSumOfPages(numPages);
    };

    const getVisibleHeight = (element: HTMLElement) => {
      const { scrollTop, clientHeight } = reactPdfContainer;
      const scrollBot = scrollTop + clientHeight;
      const containerRect: DOMRect =
        reactPdfContainer?.getBoundingClientRect() as DOMRect;
      const eleRect = element.getBoundingClientRect();
      const eleTop = eleRect.top - containerRect?.top + scrollTop;
      const eleBot = eleTop + element.offsetHeight;
      const visibleTop = eleTop < scrollTop ? scrollTop : eleTop;
      const visibleBot = eleBot > scrollBot ? scrollBot : eleBot;
      const currentPage = Math.ceil(scrollTop / PAGE_HEIGHT);
      setPage?.(currentPage);

      return visibleBot - visibleTop;
    };

    useEffect(() => {
      const pages = document.querySelectorAll(REACT_PDF_PAGE_CLASS_SELECTOR);
      reactPdfContainer?.addEventListener(
        'scroll',
        () => {
          for (let i = 0; i < pages.length; i++) {
            if (
              getVisibleHeight(pages[i] as HTMLElement) >=
              reactPdfContainer.clientHeight / 2
            ) {
              setPageNumber(i + 1);
            }
          }
        },
        false,
      );

      return () => {
        reactPdfContainer?.removeEventListener('scroll', () => ({}));
      };
    }, [reactPdfContainer]);

    return (
      <>
        <Document
          inputRef={documentRef as LegacyRef<HTMLDivElement>}
          className={
            file ? `${styles.uploadedDocument} uploaded-document shadow` : ''
          }
          renderMode="canvas"
          options={{ workerSrc: '/pdf.worker.js' }}
          file={file}
          loading="Loading"
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onLoadError}
        >
          {Array.from(new Array(+sumOfPages)).map((page, index) => (
            <Page pageNumber={index + 1} key={index} />
          ))}
        </Document>

        <div
          className={
            !file
              ? 'd-none'
              : 'position-absolute bottom-0 text-center w-100 fw-normal custom-fs-3 text-muted'
          }
        >
          <p className={styles.numberPages}>
            {pageNumber} of {sumOfPages}
          </p>
        </div>
      </>
    );
  },
);
