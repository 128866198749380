import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { Crop, PixelCrop } from 'react-image-crop';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

import {
  canvasPreview,
  centerAspectCrop,
  getBase64StringFromDataURL,
} from '../../../utils';
import {
  ImageCrop,
  PreviewCompletedCrop,
  SettingsCrop,
  UploadSignatureInput,
  UploadSignatureLabel,
} from '../../index';
import { useDebounce } from '../../../hooks';
import { UploadRequest } from '../../../interfaces';
import {
  ACCEPTABLE_SIGNATURE_IMAGE_FORMAT,
  FILE_TYPE_ERROR,
  MAX_FILE_SIZE_ERROR,
} from '../../../constants';

interface UploadSignatureDialogProps {
  onClose: () => void;
  title?: string;
  imgRef: React.RefObject<HTMLImageElement>;
  canvasRef: React.RefObject<HTMLCanvasElement> | null;
  setUploadPayload: Dispatch<SetStateAction<UploadRequest>>;
  showDialog: boolean;
}

interface StatefulValuesProps {
  file: File | null;
  imgSrc: string;
  lastSection: boolean;
}

export interface CropSettingsProps {
  aspect: number | undefined;
  scale: number;
  rotate: number;
}

const DEFAULT_STATEFUL_VALUES = {
  file: null,
  imgSrc: '',
  lastSection: false,
};

const MAX_FILE_SIZE = 2000000;

export const UploadSignatureDialog = ({
  onClose,
  title,
  imgRef,
  canvasRef,
  setUploadPayload,
  showDialog,
}: UploadSignatureDialogProps) => {
  const dialogPortal = document.getElementById('root') as HTMLElement;

  const [statefulValues, setStatefulValues] = useState<StatefulValuesProps>(
    DEFAULT_STATEFUL_VALUES,
  );

  const [cropSettings, setCropSettings] = useState<CropSettingsProps>({
    aspect: 16 / 9,
    scale: 1,
    rotate: 0,
  });

  const [cropValues, setCropValues] = useState<{
    crop: Crop | undefined;
    completedCrop: PixelCrop | undefined;
  }>({
    crop: undefined,
    completedCrop: {
      unit: 'px',
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    },
  });

  const [lastSection, setLastSection] = useState<boolean>(false);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (cropSettings.aspect) {
      const { width, height } = e.currentTarget;
      setCropValues({
        ...cropValues,
        crop: centerAspectCrop(width, height, cropSettings.aspect),
      });
    }
  };

  const handleRemove = () => {
    setStatefulValues({ ...statefulValues, file: null, imgSrc: '' });
    setCropValues({ crop: undefined, completedCrop: undefined });
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files.length > 0) {
      setCropValues({ ...cropValues, crop: undefined });
      const file = e.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        toast.error(MAX_FILE_SIZE_ERROR);
      } else if (
        !ACCEPTABLE_SIGNATURE_IMAGE_FORMAT.split(',').includes(file.type)
      ) {
        toast.error(FILE_TYPE_ERROR);
      } else {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setStatefulValues({
            ...statefulValues,
            file,
            imgSrc: reader.result?.toString() || '',
          }),
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    }
    e.target.value = '';
  };

  const handleSave = () => {
    if (statefulValues.file) {
      saveAs(statefulValues.file, statefulValues.file?.name || 'Untitled');
    }
  };

  const displayContent = (hide: boolean, cssClass = '') => {
    return hide ? 'd-none' : cssClass;
  };

  const onSaveImageHandler = useCallback(() => {
    onClose();
    setUploadPayload((state: UploadRequest) => ({
      ...state,
      signatureInfo: {
        ...state.signatureInfo,
        image: getBase64StringFromDataURL(
          canvasRef?.current
            ? (canvasRef?.current?.toDataURL('image/png') as string)
            : statefulValues.imgSrc,
        ),
      },
    }));
  }, [canvasRef, statefulValues]);

  const cropImageHandler = useCallback(() => {
    setLastSection(true);
  }, []);

  const onBackHandler = useCallback(() => {
    setLastSection(false);
  }, []);

  useDebounce(
    async () => {
      if (
        cropValues.completedCrop?.width &&
        cropValues.completedCrop?.height &&
        imgRef?.current &&
        canvasRef?.current &&
        !lastSection
      ) {
        await canvasPreview(
          imgRef?.current,
          canvasRef?.current,
          cropValues.completedCrop,
          cropSettings.scale,
          cropSettings.rotate,
        );
      }
    },
    200,
    [
      imgRef,
      canvasRef,
      cropValues.completedCrop,
      cropSettings.scale,
      cropSettings.rotate,
      lastSection,
    ],
  );

  useEffect(() => {
    const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
      if ((e.key || e.code) === 'Escape') {
        onClose();
      }
    };

    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  const uploadSignatureDialogTemplate = (
    <div
      className={displayContent(
        !showDialog,
        `modal d-flex justify-content-center bg-black bg-opacity-50 ${
          statefulValues.imgSrc && !lastSection
            ? 'align-items-baseline'
            : 'align-items-center'
        }`,
      )}
      onClick={onClose}
    >
      <div
        className="modal-content w-40 min-vh-50 p-5 bg-grey-3"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header d-flex justify-content-center border-0 pt-0">
          <h3 className="modal-title">{title}</h3>
        </div>
        <div className="modal-body p-0">
          {statefulValues.file && (
            <UploadSignatureLabel
              file={statefulValues.file}
              handleRemove={handleRemove}
              display={displayContent(lastSection)}
            />
          )}
          <UploadSignatureInput
            onSelectFile={onSelectFile}
            display={displayContent(Boolean(statefulValues.file))}
          />
          {statefulValues.imgSrc && (
            <>
              <ImageCrop
                crop={cropValues.crop}
                setCropValues={
                  setCropValues as Dispatch<SetStateAction<unknown>>
                }
                imgRef={imgRef}
                imgSrc={statefulValues.imgSrc}
                onImageLoad={onImageLoad}
                scale={cropSettings.scale}
                rotate={cropSettings.rotate}
                display={displayContent(lastSection, 'd-flex')}
              />
              {cropValues.completedCrop && (
                <>
                  <PreviewCompletedCrop
                    previewCanvasRef={canvasRef}
                    completedCrop={cropValues.completedCrop}
                  />
                  <SettingsCrop
                    imgSrc={statefulValues.imgSrc}
                    setCropSettings={
                      setCropSettings as Dispatch<
                        SetStateAction<CropSettingsProps>
                      >
                    }
                    display={displayContent(lastSection)}
                  />
                </>
              )}
            </>
          )}
          <div className={displayContent(!lastSection, 'd-flex flex-column')}>
            <button
              className="btn btn-outline-primary my-4"
              onClick={() => {
                handleRemove();
                setLastSection(false);
              }}
            >
              Choose Another Image
            </button>
            <button
              className="btn btn-outline-primary text-primary mb-4"
              onClick={handleSave}
            >
              Save File to your Computer
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between text-break">
          <button
            onClick={onClose}
            className={displayContent(
              lastSection,
              'btn btn-outline-primary flex-fill me-4',
            )}
          >
            Close
          </button>
          <button
            type="button"
            className={displayContent(lastSection, 'btn btn-primary flex-fill')}
            disabled={!statefulValues.file}
            onClick={cropImageHandler}
          >
            Crop
          </button>
          <button
            type="button"
            className={displayContent(
              !lastSection,
              'btn btn-outline-primary flex-fill me-4',
            )}
            disabled={!cropValues.completedCrop}
            onClick={onBackHandler}
          >
            Back
          </button>
          <button
            type="button"
            className={displayContent(
              !lastSection,
              'btn btn-primary flex-fill',
            )}
            disabled={!cropValues.completedCrop}
            onClick={onSaveImageHandler}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
  return ReactDOM.createPortal(uploadSignatureDialogTemplate, dialogPortal);
};
