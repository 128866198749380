import { useField } from 'formik';
import { FC, memo, useCallback, useMemo, useRef } from 'react';
import { MultiSelect as ReactMultiSelect } from 'react-multi-select-component';
import { Option } from '../../interfaces';
import { dispatchInputChangeEvent, emptyFunction } from '../../utils/utils';

interface MultiSelectProps {
  name: string;
  placeholder: string;
  value: string;
  onChange: (values: string | string[]) => void;
  options: Option[];
}

export const MultiSelect: FC<MultiSelectProps> = memo(
  ({ name, value = [], onChange, options }) => {
    const [_, __, helpers] = useField(name);
    const inputRef = useRef<HTMLInputElement>(null);

    const currentValue = useMemo(
      () => (value.length ? (value as string).split(',') : []),
      [value],
    );

    const currentOptions = useMemo(
      () =>
        value.length
          ? (value as string)
              .split(',')
              .map((item: string) => ({ label: item, value: item }))
          : [],
      [value],
    );

    const onChangeHandler = useCallback((selectedOptions: Option[]) => {
      dispatchInputChangeEvent(inputRef.current as HTMLElement);
      const inputValue = selectedOptions.map((option) => option.value);
      const newValue = inputValue.join(',');
      helpers.setValue(newValue);
      helpers.setError(undefined);
      onChange(newValue);
    }, []);

    return (
      <div>
        <input
          className="d-none"
          type="text"
          ref={inputRef}
          onChange={emptyFunction}
          value={[...currentValue]}
          name={name}
        />
        <ReactMultiSelect
          options={options}
          value={currentOptions}
          onChange={onChangeHandler}
          labelledBy="Select"
        />
      </div>
    );
  },
);
