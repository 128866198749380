import { FC, memo, useEffect, useMemo, useState } from 'react';

import { DocumentView } from '../DocumentView/DocumentView';
import { ButtonSetting, ProfileHistory } from '../../interfaces';
import { DocumentStatus, Pages } from '../../enums';
import { StatusDetails } from '../StatusDetails/StatusDetails';
import { FileType } from '../../enums/file-type';
import { useDocument } from '../../hooks';

import styles from './HistoryDetails.module.scss';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { handleError } from '../../utils/utils';

interface HistoryDetailsProps {
  documentHasChanged: () => void;
  historyItem: ProfileHistory;
  page: Pages;
}

export const HistoryDetails: FC<HistoryDetailsProps> = memo(
  ({ documentHasChanged, historyItem, page }) => {
    const [file, setFile] = useState<FileType>(null);
    const [hasPendingStatus, setHasPendingStatus] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [otp, setOtp] = useState('');
    const {
      cancelDocumentSign,
      downloadFile,
      getDocumentById,
      archiveDocument,
      restoreDocument,
      deleteMultipleDocuments,
    } = useDocument();

    const closeButton: HTMLButtonElement | null = useMemo(
      () => document.querySelector('.close-btn'),
      [historyItem],
    );

    const cancelBtn: ButtonSetting = {
      label: 'Cancel Signing',
      iconClass: 'icon-increase-rotate',
      class: 'btn-outline-primary',
      parentClass: 'col-12',
      onClick: () => {
        cancelDocumentSign(historyItem.documentId)
          .then(() => {
            setHasPendingStatus(false);
            historyItem.status = DocumentStatus.CANCELED;
            documentHasChanged();
            toast.success('The document signing has been canceled.');
            closeButton?.click();
          })
          .catch((error: AxiosError) => handleError(error));
      },
    };

    const archiveBtn: ButtonSetting = {
      label: 'Archive',
      iconClass: 'icon-archive',
      class: 'btn-outline-primary',
      parentClass: 'col-12',
      onClick: () => {
        archiveDocument(historyItem.documentId)
          .then(() => {
            documentHasChanged();
            toast.success('The document has been archived.');
            closeButton?.click();
          })
          .catch((error: AxiosError) => handleError(error));
      },
    };

    const restoreBtn: ButtonSetting = {
      label: 'Restore PDF',
      iconClass: 'icon-restore',
      class: 'btn-primary',
      parentClass: 'col-12',
      onClick: () => {
        restoreDocument(historyItem.documentId)
          .then(() => {
            documentHasChanged();
            toast.success('The document has been restored.');
            closeButton?.click();
          })
          .catch((error: AxiosError) => handleError(error));
      },
    };

    const deleteBtn: ButtonSetting = {
      label: 'Delete PDF',
      iconClass: 'icon-delete',
      class: 'btn-danger delete-button',
      parentClass: 'col-12 mt-2',
      onClick: () => {
        deleteMultipleDocuments([historyItem.documentId as string])
          .then(() => {
            documentHasChanged();
            toast.success('The document has been deleted.');
            closeButton?.click();
          })
          .catch((error: AxiosError) => handleError(error));
      },
    };

    const downloadBtn: ButtonSetting = {
      label: 'Download PDF',
      iconClass: 'icon-download-key',
      class: 'btn-primary mt-2',
      parentClass: 'col-12',
      onClick: () => {
        downloadFile(historyItem.documentId, historyItem.fileName).then(() => {
          toast.success('The document has been downloaded.');
          closeButton?.click();
        });
      },
    };

    const getButtonsHistory: ButtonSetting[] = useMemo(() => {
      if (historyItem.status === DocumentStatus.SIGNED) {
        return [downloadBtn, archiveBtn];
      } else if (historyItem.status === DocumentStatus.PENDING) {
        return [downloadBtn, cancelBtn];
      } else {
        return [downloadBtn, archiveBtn];
      }
    }, [historyItem]);

    const getButtonsArchived: ButtonSetting[] = useMemo(
      () => [
        deleteBtn,
        { ...downloadBtn, class: 'btn-outline-primary mt-2' },
        restoreBtn,
      ],
      [historyItem],
    );

    const config = {
      showDetails: true,
      btn: page === Pages.History ? getButtonsHistory : getButtonsArchived,
      otp: otp || '',
    };

    useEffect(() => {
      if (historyItem.documentId) {
        setLoadingFile(true);
        getDocumentById(historyItem.documentId)
          .then(({ data }: { data: FileType }) => {
            setFile(data);
            setLoadingFile(false);
          })
          .catch(() => setLoadingFile(false));
        setHasPendingStatus(historyItem.status === DocumentStatus.PENDING);
      }

      return () => {
        setLoadingFile(false);
        setFile(null);
        setOtp('');
      };
    }, [historyItem.documentId]);

    return (
      <div
        className="modal fade"
        id="historyDetails"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div
            className={`${styles.historyDetails} history-details modal-content`}
          >
            <div className="modal-header border-bottom-0">
              <h6 className="modal-title">Document Information</h6>
              <button
                type="button"
                className={`${styles.closeBtn} close-btn border-0 p-0`}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="icon-close text-primary" />
              </button>
            </div>
            <div className="modal-body mb-4">
              <div className="row">
                <div
                  className={`${styles.historyPdf} history-pdf col-md-7 col-sm-6 position-relative`}
                >
                  {file ? (
                    <DocumentView file={file} />
                  ) : (
                    <div className="min-vh-50 d-flex justify-content-center bg-white shadow bg-white">
                      <p className="p2-600 mt-5">
                        {loadingFile ? 'Loading' : 'No file'}
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    hasPendingStatus
                      ? styles.pendingStatus
                      : styles.canceledStatus
                  } col-md-5 col-sm-6`}
                >
                  <StatusDetails
                    config={config}
                    details={{
                      ...historyItem,
                      signed: !!historyItem.signed,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
