import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Props } from '../interfaces';
import { RootState } from '.';

const tableSelectionSlice = createSlice({
  name: 'tableSelection',
  initialState: {},
  reducers: {
    setTableSelection: (
      state,
      action: PayloadAction<{ key: string; records?: Props[] }>,
    ) => ({
      ...state,
      [action?.payload.key]: action.payload.records,
    }),
    removeTableSelection: (
      state,
      action: PayloadAction<{
        key: string;
        filter: (record: Props) => boolean;
      }>,
    ) => ({
      ...state,
      [action?.payload.key]:
        (state as Props)[action?.payload.key]?.filter(
          action?.payload?.filter,
        ) ?? undefined,
    }),
  },
});
export const { setTableSelection, removeTableSelection } =
  tableSelectionSlice.actions;

export const tableSelectionSelector = (key: string) => (state: RootState) =>
  (state.tableSelection as Props)[key];

export const tableSelection = tableSelectionSlice.reducer;
