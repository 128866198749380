import {
  BaseSyntheticEvent,
  FC,
  memo,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { getDetails, uploadFile } from '../../utils';
import { DocumentType, StatusStep } from '../../enums';
import { ButtonSetting, Details } from '../../interfaces';
import { FileType } from '../../enums/file-type';
import { buttonsMap, statusMap, titleMap } from './constants';
import { Wizard } from '../Wizard/Wizard';
import { useDocument } from '../../hooks';

export const Verify: FC = memo(() => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [viewDocStep, setViewDocStep] = useState<StatusStep>(StatusStep.FIRST);
  const [file, setFile] = useState<FileType>(null);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [details, setDetails] = useState<Details>({} as Details);
  const controllerRef = useRef<AbortController | null>();
  const { verifySignature } = useDocument();

  const handleBackBtn = useCallback(() => {
    if (!step && !file) {
      navigate(-1);
    } else {
      setStep(step > 0 ? step - 1 : 0);
      setViewDocStep(StatusStep.FIRST);
      step === 0 && setFile(null);
    }
  }, [step]);

  const onChangeStep = useCallback((value: StatusStep, stepNumber = 1) => {
    setViewDocStep(value);
    setStep(stepNumber);
  }, []);

  const { Component, ...configStatus } = statusMap.get(viewDocStep) || {};

  const onChange = useCallback(
    (e: BaseSyntheticEvent): void => {
      uploadFile(e, (newFile: FileType, readedFile) => {
        setFile(newFile);
        setUploadedFile(readedFile);
        controllerRef.current = new AbortController();
      });
    },
    [controllerRef],
  );

  const verifySignatureHandler = useCallback(() => {
    onChangeStep(StatusStep.VERIFY_UPLOAD);
    verifySignature(
      { file: uploadedFile },
      { signal: controllerRef.current?.signal },
    )
      .then(({ data }) => {
        setDetails(getDetails(data, uploadedFile?.name || ''));
        onChangeStep(StatusStep.SUCCESS);
        controllerRef.current = null;
      })
      .catch(() => {
        onChangeStep(StatusStep.ERROR);
        controllerRef.current = null;
      });
  }, [uploadedFile]);

  return (
    <Wizard
      title="Verify signature status"
      step={step}
      section={DocumentType.VERIFY}
      subtitle={
        titleMap.get(
          step === 0 && file ? StatusStep.VERIFY_UPLOAD : viewDocStep,
        ) as string
      }
      component={() => {
        return (
          Component && <Component config={configStatus} details={details} />
        );
      }}
      buttons={
        (buttonsMap.get(viewDocStep) as ({}) => ButtonSetting[])({
          handleBackBtn,
          onChangeStep,
          disabled: !file,
          verifySignatureHandler,
        }) as ButtonSetting[]
      }
      viewDocStep={viewDocStep}
      onChange={onChange}
      file={file}
      onLoadError={() => setFile(null)}
    />
  );
});
