export enum HistoryKey {
  STATUS = 'statuses',
  SORT_BY_NAME = 'sortByName',
  SORT_BY_CREATED = 'sortByCreated',
  SORT_BY_SIGNED = 'sortBySigned',
  SELECT = 'select',
}

export enum TooltipDirection {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  AUTO = 'auto',
}

export enum FieldKeys {
  Name = 'name',
  Status = 'statuses',
  Created = 'created',
  Signed = 'signed',
  CreatedStart = 'createdStart',
  CreatedEnd = 'createdEnd',
  SignedStart = 'signedStart',
  SignedEnd = 'signedEnd',
  SignedAt = 'signedAt',
  FileName = 'fileName',
  Asc = 'asc',
}

export enum InputType {
  Text = 'text',
  Date = 'date',
  Select = 'select',
  CheckBox = 'checkbox',
}

export enum Actions {
  View = 'view',
  Archive = 'archive',
  Delete = 'delete',
  Cancel = 'cancel',
  Restore = 'restore',
}
