import React, { useEffect } from 'react';
import styles from './TermsAndConditions.module.scss';
import { MenuContent } from '../../components/MenuContent/MenuContent';
import { MenuItems } from '../../interfaces';
import { TermsAndConditionsId } from '../../enums';

export const TermsAndConditions = () => {
  const menuItems: MenuItems[] = [
    {
      label: 'Terms and conditions',
      id: TermsAndConditionsId.TERMS_AND_CONDITIONS,
    },
    {
      label: 'Interpretation and Definitions',
      id: TermsAndConditionsId.INTERPRETATION_AND_DEFINITIONS,
    },
    {
      label: 'Acknowledgement',
      id: TermsAndConditionsId.ACKNOWLEDGEMENT,
    },
    {
      label: 'Links to Other Websites',
      id: TermsAndConditionsId.LINKS,
    },
    {
      label: 'License',
      id: TermsAndConditionsId.LICENCE,
    },
    {
      label: 'Hyperlinking to our Content',
      id: TermsAndConditionsId.HYPERLINKING,
    },
    {
      label: 'Termination',
      id: TermsAndConditionsId.TERMINATION,
    },
    {
      label: 'Limitation of Liability',
      id: TermsAndConditionsId.LIMITATION,
    },
    {
      label: 'Governing Law',
      id: TermsAndConditionsId.GOVERNING_LAW,
    },
    {
      label: 'Severability and Waiver',
      id: TermsAndConditionsId.SEVERABILITY_AND_WAIVER,
    },
    {
      label: 'Translation Interpretation',
      id: TermsAndConditionsId.TRANSLATION,
    },
    {
      label: 'Code of Conduct',
      id: TermsAndConditionsId.CODE_OF_CONDUCT,
    },
    {
      label: 'Changes to These Terms and Conditions',
      id: TermsAndConditionsId.CHANGES_TERM_AND_CONDITIONS,
    },
    {
      label: 'Contact Us',
      id: TermsAndConditionsId.CONTACT_US,
    },
  ];

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <section className={`${styles.termsAndConditions} container mt-5 p-5`}>
      <div className="row">
        <div className="col-9">
          <header>
            <h2 id={TermsAndConditionsId.TERMS_AND_CONDITIONS}>
              Terms and conditions
            </h2>
          </header>
          <p className="small">Last updated: August 23, 2022</p>

          <p className="mt-3">
            Please read these terms and conditions carefully before using Our
            Service, they outline the rules and regulations for the use of
            ZamSign Portal, located at{' '}
            <a href="https://zamsign.gsb.gov.zm" target="_self">
              https://zamsign.gsb.gov.zm/
            </a>
          </p>

          <p>
            By accessing this website/app, we assume you accept these terms and
            conditions. Do not continue to use WebsiteName/AppName if you do not
            agree to take all of the terms and conditions stated on this page.
          </p>

          <h4 className="mt-4">Interpretation</h4>

          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>

          <h4
            className="mt-4"
            id={TermsAndConditionsId.INTERPRETATION_AND_DEFINITIONS}
          >
            Interpretation and Definitions
          </h4>
          <h6>Interaction</h6>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions.
            <br />
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or in plural.
          </p>

          <h6 className="mt-3">Definitions </h6>
          <p>For the purposes of these Terms and Conditions: </p>

          <ul>
            <li>
              <p>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                &quot;control&quot; means ownership of 50% or more of the
                shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.
              </p>
            </li>
            <li>
              <p>
                <strong>Company</strong> (referred to as either &quot;the
                Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
                in this Agreement) refers to ZamServices - Government e-Services
                Directory, P.O. Box 30135. Independence Avenue, Lusaka.
              </p>
            </li>
            <li>
              <p>
                <strong>Country</strong> refers to: Zambia
              </p>
            </li>
            <li>
              <p>
                <strong>Service</strong> refers to the Website/App.
              </p>
            </li>
            <li>
              <p>
                <strong>Terms and Conditions</strong> (also referred as
                &quot;Terms&quot;) mean these Terms and Conditions that form the
                entire agreement between You and the Company regarding the use
                of the Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Third-party Social Media Service</strong> means any
                services or content (including data, information, products or
                services) provided by a third-party that may be displayed,
                included or made available by the Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Website</strong> refers to Website, accessible from
                https://zamsign.gsb.gov.zm/
              </p>
            </li>
            <li>
              <p>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
          </ul>

          <h4 className="mt-4" id={TermsAndConditionsId.ACKNOWLEDGEMENT}>
            Acknowledgement
          </h4>
          <p>
            These are the Terms and Conditions governing the use of this Service
            and the agreement that operates between You and the Company. These
            Terms and Conditions set out the rights and obligations of all users
            regarding the use of the Service.
          </p>
          <p>
            Your access to and use of the Service is conditioned on Your
            acceptance of and compliance with these Terms and Conditions. These
            Terms and Conditions apply to all visitors, users and others who
            access or use the Service.
          </p>
          <p>
            By accessing or using the Service You agree to be bound by these
            Terms and Conditions. If You disagree with any part of these Terms
            and Conditions then You may not access the Service.
          </p>
          <p>
            Your access to and use of the Service is also conditioned on Your
            acceptance of and compliance with the Privacy Policy of the Company.
            Our Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your personal information when You
            use the Application or the Website and tells You about Your privacy
            rights and how the law protects You. Please read Our Privacy Policy
            carefully before using Our Service.
          </p>

          <h4 className="mt-4" id={TermsAndConditionsId.LINKS}>
            Links to Other Websites
          </h4>
          <p>
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by the Company.
          </p>
          <p>
            The Company has no control over, and assumes no responsibility for,
            the content, privacy policies, or practices of any third party web
            sites or services. You further acknowledge and agree that the
            Company shall not be responsible or liable, directly or indirectly,
            for any damage or loss caused or alleged to be caused by or in
            connection with the use of or reliance on any such content, goods or
            services available on or through any such web sites or services.
          </p>
          <p>
            We strongly advise You to read the terms and conditions and privacy
            policies of any third-party web sites or services that You visit.
          </p>

          <h4 className="mt-4" id={TermsAndConditionsId.LICENCE}>
            License
          </h4>
          <p>
            Unless otherwise stated, CompanyName and/or its licensors own the
            intellectual property rights for all material on WebsiteName. All
            intellectual property rights are reserved. You may access this from
            WebsiteName for your own personal use subjected to restrictions set
            in these terms and conditions. You must not:
          </p>
          <ul>
            <li>
              <p>Copy or republish material from WebsiteName</p>
            </li>
            <li>
              <p>Sell, rent, or sub-license material from WebsiteName</p>
            </li>
            <li>
              <p>Reproduce, duplicate or copy material from WebsiteName</p>
            </li>
            <li>
              <p>Redistribute content from WebsiteName</p>
            </li>
          </ul>
          <p>This Agreement shall begin on the date hereof.</p>

          <h4 className="mt-4" id={TermsAndConditionsId.HYPERLINKING}>
            Hyperlinking to our Content
          </h4>
          <p>
            The following organizations may link to our Website without prior
            written approval:
          </p>
          <ul>
            <li>
              <p> Government agencies; </p>
            </li>
            <li>
              <p> Search engines; </p>
            </li>
            <li>
              <p> News organizations; </p>
            </li>
            <li>
              <p>
                Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses;
              </p>
            </li>
          </ul>
          <p>
            These organizations may link to our home page, to publications, or
            to other Website information so long as the link: (a) is not in any
            way deceptive; (b) does not falsely imply sponsorship, endorsement,
            or approval of the linking party and its products and/or services;
            and (c) fits within the context of the linking party&apos;s site.
          </p>

          <h4 className="mt-4" id={TermsAndConditionsId.TERMINATION}>
            Termination
          </h4>
          <p>
            We may terminate or suspend Your access immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if You breach these Terms and Conditions.
          </p>
          <p>
            Upon termination, Your right to use the Service will cease
            immediately.
          </p>

          <h4 className="mt-4" id={TermsAndConditionsId.LIMITATION}>
            Limitation of Liability
          </h4>
          <p>
            Notwithstanding any damages that You might incur, the entire
            liability of the Company and any of its suppliers under any
            provision of this Terms and Your exclusive remedy for all of the
            foregoing shall be limited to the amount actually paid by You
            through the Service or 100 USD if You haven&apos;t purchased
            anything through the Service.
          </p>
          <p>
            To the maximum extent permitted by applicable law, in no event shall
            the Company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever (including, but not
            limited to, damages for loss of profits, loss of data or other
            information, for business interruption, for personal injury, loss of
            privacy arising out of or in any way related to the use of or
            inability to use the Service, third-party software and/or
            third-party hardware used with the Service, or otherwise in
            connection with any provision of this Terms), even if the Company or
            any supplier has been advised of the possibility of such damages and
            even if the remedy fails of its essential purpose.
          </p>
          <p>
            Some states do not allow the exclusion of implied warranties or
            limitation of liability for incidental or consequential damages,
            which means that some of the above limitations may not apply. In
            these states, each party&apos;s liability will be limited to the
            greatest extent permitted by law.
          </p>

          <h4 className="mt-4" id={TermsAndConditionsId.GOVERNING_LAW}>
            Governing Law
          </h4>
          <p>
            The laws of the Zambia, excluding its conflicts of law rules, shall
            govern this Terms and Your use of the Service. Your use of the
            Application may also be subject to other local, state, national, or
            international laws.{' '}
          </p>

          <h4
            className="mt-4"
            id={TermsAndConditionsId.SEVERABILITY_AND_WAIVER}
          >
            Severability and Waiver
          </h4>
          <h6>Severability</h6>
          <p>
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.{' '}
          </p>

          <h6>Waiver</h6>
          <p>
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under this Terms shall not
            effect a party&apos;s ability to exercise such right or require such
            performance at any time thereafter nor shall be the waiver of a
            breach constitute a waiver of any subsequent breach.{' '}
          </p>

          <h4 className="mt-4" id={TermsAndConditionsId.TRANSLATION}>
            Translation Interpretation
          </h4>
          <p>
            These Terms and Conditions may have been translated if We have made
            them available to You on our Service.
          </p>
          <p>
            You agree that the original English text shall prevail in the case
            of a dispute.
          </p>

          <h4 className="mt-4" id={TermsAndConditionsId.CODE_OF_CONDUCT}>
            Code of Conduct
          </h4>
          <ul>
            <li>
              <p>
                Content, material or actions that violate these Terms are not
                permitted. By agreeing to these Terms, you’re under the
                obligation to follow these rules:
              </p>

              <ol>
                <li>
                  <p>Don’t do anything illegal.</p>
                </li>
                <li>
                  <p>
                    Don’t engage in any activity that exploits, harms or
                    threatens to harm anyone.
                  </p>
                </li>
                <li>
                  <p>
                    Don’t send spam or engage in phishing. Spam is unwanted or
                    unsolicited bulk email, postings, contact requests, SMS
                    (text messages), instant messages or similar electronic
                    communications. Phishing is sending emails or other
                    electronic communications to fraudulently or unlawfully
                    induce recipients to reveal personal or sensitive
                    information, such as passwords, dates of birth, National
                    Insurance Numbers, passport numbers, credit card
                    information, financial information or other sensitive
                    information, or to gain access to accounts or records,
                    exfiltration of documents or other sensitive information,
                    payment and/or financial benefit.
                  </p>
                </li>
                <li>
                  <p>
                    Don’t publicly display or use the Services to share any
                    inappropriate content or other material (involving, for
                    example, nudity, bestiality, pornography, offensive
                    language, graphic violence or criminal activity).
                  </p>
                </li>
                <li>
                  <p>
                    Don’t engage in activity that is fraudulent, false or
                    misleading (e.g. asking for money under false pretences,
                    impersonating someone else).
                  </p>
                </li>
                <li>
                  <p>
                    {' '}
                    Don’t willfully circumvent any restrictions on access to or
                    availability of the Services.{' '}
                  </p>
                </li>
                <li>
                  <p>
                    Don’t engage in activity that is harmful to you, the
                    Services or others (e.g. transmitting viruses, stalking,
                    posting terrorist or violent extremist content,
                    communicating hate speech or advocating violence against
                    others).
                  </p>
                </li>
                <li>
                  <p>
                    Don’t infringe upon the rights of others (e.g, unauthorised
                    sharing of copyrighted material).
                  </p>
                </li>
                <li>
                  <p>
                    Don’t engage in activity that violates the privacy of
                    others.
                  </p>
                </li>
                <li>
                  <p> Don’t help others break these rules. </p>
                </li>
              </ol>
            </li>
          </ul>

          <h4
            className="mt-4"
            id={TermsAndConditionsId.CHANGES_TERM_AND_CONDITIONS}
          >
            Changes to These Terms and Conditions
          </h4>
          <p>
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. If a revision is material We will make
            reasonable efforts to provide at least 30 days&apos; notice prior to
            any new terms taking effect. What constitutes a material change will
            be determined at Our sole discretion.
          </p>
          <p>
            By continuing to access or use Our Service after those revisions
            become effective, You agree to be bound by the revised terms. If You
            do not agree to the new terms, in whole or in part, please stop
            using the website and the Service.
          </p>

          <h4 className="mt-4" id={TermsAndConditionsId.CONTACT_US}>
            Contact Us
          </h4>
          <p>
            If you have any questions about these Terms and Conditions, You can
            contact us:
          </p>
          <ul>
            <li>
              <p>
                By email:{' '}
                <a href="mailto: zam@gov.zm" className="text-link">
                  zam@gov.zm
                </a>
              </p>
            </li>
            <li>
              <p>
                By phone number:{' '}
                <a href="tel:+260 211253438" className="text-link">
                  +260 211253438
                </a>
              </p>
            </li>
          </ul>
        </div>

        <div className={`${styles.menu} col-3 position-fixed`}>
          <MenuContent menuItems={menuItems} />
        </div>
      </div>
    </section>
  );
};
