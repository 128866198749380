import { FC, memo, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useField } from 'formik';

import { DateRangeValueType, InputFilter } from '../../interfaces';
import { DATE_RANGE_DELIMITER } from '../../constants';

export const DatePickerRange: FC<InputFilter> = memo(
  ({ placeholder, isReadOnly, name, transformValue, maxDate, minDate }) => {
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;
    const [dateRange, setDateRange] = useState<DateRangeValueType>([
      undefined,
      undefined,
    ]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
      if (!value && startDate) {
        setDateRange([undefined, undefined]);
      } else if (value && !startDate) {
        setDateRange(
          value
            .split(DATE_RANGE_DELIMITER)
            .map((item: string) => (item ? new Date(item) : undefined)),
        );
      }
    }, [value]);

    return (
      <div>
        <DatePicker
          {...field}
          className="form-control"
          selectsRange
          placeholderText={placeholder}
          startDate={startDate}
          endDate={endDate}
          maxDate={maxDate as Date}
          minDate={minDate as Date}
          onChange={(changedValue: (Date | null)[]) => {
            setDateRange(changedValue as (Date | undefined)[]);
            setValue(
              transformValue
                ? transformValue(changedValue as (Date | undefined)[])
                : changedValue,
            );
          }}
          isClearable={!isReadOnly}
        />
      </div>
    );
  },
);
